import { ChangeEventHandler } from 'react'
import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import { FaChevronDown } from 'react-icons/fa'
import { UseFormReset } from 'react-hook-form'

import XmlFileUploadButton from './XmlFileUploadButton'
import {
  getDefaultAttributeMap,
  ServiceProviderInputType,
  useParseSpFromXml,
} from '../../../../libs/hooks'
import utils from '../../../../libs/utils'
import { IServiceProviderDto } from '../../../../ses.idp.web.api'

type ServiceProviderMenuProps = {
  openModal: () => void
  setFormValues: UseFormReset<ServiceProviderInputType>
}

type Preset = {
  id: string
  label: string
  values: ServiceProviderInputType
}

const presets: Preset[] = [
  {
    id: 'aad',
    label: 'AzureAD',
    values: {
      formType: 'add',
      variant: 'SAML',
      name: '',
      entityId: 'urn:federation:MicrosoftOnline',
      userActionMode: -1,
      signingCertificate: null,
      xmlFile: null,
      sessionDurationSec: 900,
      subjectIdJwtClaimType: 'nameImmutableID',
      assertionConsumerServices: [
        {
          id: 0,
          binding: 'HTTP-POST',
          location: 'https://login.microsoftonline.com/login.srf',
        },
      ],
      singleLogoutServices: [
        {
          id: 0,
          binding: 'HTTP-POST',
          location: '',
        },
      ],
      requirePkce: false,
      allowedScopes: [],
      redirectUris: [],
      clientSecrets: [],
      attributeMappings: getDefaultAttributeMap(),
    },
  },
  {
    id: 'adfs',
    label: 'ADFS',
    values: {
      formType: 'add',
      variant: 'SAML',
      name: '',
      entityId: 'https://qavnteam.asia/adfs/services/trust',
      userActionMode: -1,
      signingCertificate: null,
      xmlFile: null,
      sessionDurationSec: 900,
      subjectIdJwtClaimType: 'nameImmutableID',
      assertionConsumerServices: [
        {
          id: 0,
          binding: 'HTTP-POST',
          location: 'https://qavnteam.asia/adfs/ls/',
        },
      ],
      singleLogoutServices: [
        {
          id: 0,
          binding: 'HTTP-POST',
          location: '',
        },
      ],
      requirePkce: false,
      allowedScopes: [],
      redirectUris: [],
      clientSecrets: [],
      attributeMappings: getDefaultAttributeMap(),
    },
  },
]

function dtoToFormValues(dto: IServiceProviderDto): ServiceProviderInputType {
  const signingCertificate = (() => {
    if (dto.signingCertificates && dto.signingCertificates.length > 0) {
      return JSON.parse(dto.signingCertificates[0].name)
    }
    return null
  })()

  return {
    formType: 'import',
    variant: 'SAML',
    name: '',
    id: dto.id,
    entityId: dto.entityId,
    userActionMode: dto.userActionMode || -1,
    signingCertificate,
    xmlFile: null,
    sessionDurationSec: dto.sessionDurationSec,
    subjectIdJwtClaimType: dto.subjectIdJwtClaimType,
    assertionConsumerServices: dto.assertionConsumerServices,
    singleLogoutServices: dto.singleLogoutServices,
    redirectUris: dto.redirectUris,
    clientSecrets: dto.clientSecrets,
    allowedScopes: dto.allowedScopes,
    requirePkce: dto.requirePkce,
    attributeMappings: dto.attributeMappings,
  }
}

export function ServiceProviderMenu({
  openModal,
  setFormValues,
}: ServiceProviderMenuProps) {
  const parseXML = useParseSpFromXml()

  const reset = () => {
    setFormValues({ xmlFile: null, signingCertificate: null })
    return
  }

  const handleImport: ChangeEventHandler<HTMLInputElement> = e => {
    if (!e.target.files?.length) {
      reset()
      return
    }

    return parseXML
      .mutateAsync(e.target.files)
      .then(dto => {
        const values = dtoToFormValues(dto)
        values.xmlFile = e.target.files[0]
        setFormValues(values)
      })
      .then(openModal)
      .catch(err => {
        utils.toastError(`There was an error importing the XML file`, err.title)
      })
  }

  return (
    <Menu>
      <MenuButton as={IconButton} icon={<FaChevronDown />} />
      <MenuList>
        <MenuGroup title="Create from templates">
          {presets.map(({ id, values, label }) => (
            <MenuItem
              key={id}
              onClick={() => {
                setFormValues({ ...values })
                openModal()
              }}
            >
              {label}
            </MenuItem>
          ))}
        </MenuGroup>
        <MenuDivider />
        <MenuItem>
          <XmlFileUploadButton isLoading={false} onChange={handleImport} />
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
