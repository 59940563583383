import React, { useCallback, useState } from 'react'
import {
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Stack,
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

import { useAssignableUsersOfGroup } from '../../../libs/hooks'
import { IUsersToGroupInput } from '../../../ses.idp.web.api'

import UserTable from './UserTable'

interface Props {
  groupId: string
  isOpen: boolean
  onClose: () => void
  onSubmit: () => Promise<void>
}

function AddUsersModal({ groupId, isOpen, onSubmit, onClose }: Props) {
  const {
    setValue,
    formState: { isSubmitting, isDirty },
  } = useFormContext<IUsersToGroupInput>()
  const {
    query: { data: assignableUsers, isLoading },
    page,
    setPage,
    setSearch,
  } = useAssignableUsersOfGroup(isOpen ? groupId : null)

  const handleSelect = useCallback(
    (selection: number[]) => {
      setValue('userIds', selection, {
        shouldDirty: true,
      })
    },
    [setValue],
  )

  return (
    <Drawer size="xl" placement="right" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <form onSubmit={onSubmit}>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader w="full">
            <Stack
              w="full"
              direction={{ base: 'column', sm: 'row' }}
              spacing={3}
              alignItems={{ sm: 'center' }}
            >
              <span aria-label="Add Group">Add Users to Group</span>
            </Stack>
          </DrawerHeader>
          <DrawerBody>
            <UserTable
              items={assignableUsers?.items}
              isLoading={isLoading}
              onSelect={handleSelect}
              perPage={20}
              total={assignableUsers?.totalCount ?? 0}
              currentPage={page}
              onPageChange={setPage}
              onSearch={setSearch}
              emptyState={
                <Center fontSize="sm" p={5}>
                  No assignable user found for this group
                </Center>
              }
            />
          </DrawerBody>
          <DrawerFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              ml={4}
              type="submit"
              isLoading={isSubmitting}
              isDisabled={!isDirty}
            >
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  )
}

export default AddUsersModal
