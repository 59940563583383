import { useRef } from 'react'
import { Portal, useDisclosure } from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSearchParam } from 'react-use'

import {
  DeleteFidoKeyInput,
  RegisterFidoKeyInput,
  useDeleteFidoKey,
  useFidoKeys,
  useRegisterFidoKey,
} from '../../../libs/hooks'
import utils from '../../../libs/utils'

import FidoKeysTable from './FidoKeysTable'
import RegisterFidoKey from './RegisterFidoKey'
import DeleteFidoKey from './DeleteFidoKey'

function FidoKeysConfiguration() {
  const cancelRef = useRef()
  const registerForm = useForm<RegisterFidoKeyInput>()
  const deleteForm = useForm<DeleteFidoKeyInput>()
  const deleteModal = useDisclosure()
  const registerModal = useDisclosure({
    onOpen() {
      registerForm.reset({})
    },
  })
  const flags = useSearchParam('flags')
  const { data, isLoading } = useFidoKeys(flags || '-1')
  const { mutateAsync: registerAsync } = useRegisterFidoKey()
  const { mutateAsync: deleteAsync } = useDeleteFidoKey()

  const registerKey = registerForm.handleSubmit(input => {
    return registerAsync(input, {
      onSuccess: () => {
        utils.toastSuccess(`The FIDO Key '${input.name}' has been registered`)
        registerModal.onClose()
      },
      onError: error => {
        utils.toastError('FIDO Key registration failed!', error.title)
      },
    }).catch(_ => {})
  })

  const deleteKey = deleteForm.handleSubmit(input => {
    return deleteAsync(input, {
      onSuccess: deletedKey => {
        utils.toastSuccess(`The FIDO Key '${deletedKey.name}' has been deleted`)
        deleteModal.onClose()
      },
    }).catch(_ => {})
  })

  const openDelete = (keyId: number) => {
    const { id, name } = data.find(p => p.id === keyId)
    deleteForm.reset({ id, name })
    deleteModal.onOpen()
  }

  return (
    <div>
      <FidoKeysTable
        data={data}
        isLoading={isLoading}
        onRegister={registerModal.onOpen}
        onDelete={openDelete}
      />
      <Portal>
        <FormProvider {...registerForm}>
          <RegisterFidoKey
            leastDestructiveRef={cancelRef}
            onSubmit={registerKey}
            {...registerModal}
          />
        </FormProvider>
        <FormProvider {...deleteForm}>
          <DeleteFidoKey
            leastDestructiveRef={cancelRef}
            onSubmit={deleteKey}
            {...deleteModal}
          />
        </FormProvider>
      </Portal>
    </div>
  )
}

export default FidoKeysConfiguration
