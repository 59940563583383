import React from 'react'
import { createStandaloneToast } from '@chakra-ui/react'

import { isString, ProblemDetails } from '../types'

const toast = createStandaloneToast({
  defaultOptions: {
    duration: 15000,
    isClosable: true,
  },
})

function isLoopbackAddress(address: string) {
  // credit: https://stackoverflow.com/a/8426365
  return Boolean(
    address.match(/^localhost$|^127(?:\.\d+){0,2}\.\d+$|^(?:0*:)*?:?0*1$/),
  )
}

function isValidHttpUrl(value: string) {
  // credit: https://stackoverflow.com/a/43467144
  let url
  try {
    url = new URL(value)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

const utils = {
  maxInt32: Math.pow(2, 31) - 1,
  isLoopbackAddress,
  isValidHttpUrl,
  isNullOrWhitespace: (data: string | null | undefined) =>
    !data || !data.trim(),
  removeEmptyFields: (data: any) => {
    if (data === null || data === undefined) return

    Object.keys(data).forEach(key => {
      if (data[key] === '' || data[key] == null) {
        delete data[key]
      }
    })
  },
  // credit: https://stackoverflow.com/a/2970667
  camelize: (str: string) =>
    str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase()
      })
      .replace(/\s+/g, ''),
  stringifyCamelcase: (data: any) => {
    return JSON.stringify(data, function (key, value) {
      if (value && typeof value === 'object') {
        const replacement = {} as Record<string, unknown>
        for (let k in value) {
          if (Object.hasOwnProperty.call(value, k)) {
            replacement[k && k.charAt(0).toUpperCase() + k.substring(1)] =
              value[k]
          }
        }
        return replacement
      }
      return value
    })
  },
  toastSuccess: (title?: any, description?: any): void => {
    const t =
      isString(title) || React.isValidElement(description)
        ? title
        : 'Operation succeeded!'
    const desc =
      isString(description) || React.isValidElement(description)
        ? description
        : null
    toast({ status: 'success', title: t, description: desc })
  },
  toastError: (title?: any, description?: any): void => {
    const problem = ProblemDetails.parse(title)
    const desc =
      React.isValidElement(description) || isString(description)
        ? description
        : null
    toast({ status: 'error', title: problem.title, description: desc })
  },
}

export default utils
