import {
  Box,
  Divider,
  Flex,
  Icon,
  IconButton,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { FaArrowRight } from 'react-icons/fa'
import { GrCertificate } from 'react-icons/gr'

import { EmptyState, Pagination, SearchBox } from '../../../components'
import { IServiceProviderDto } from '../../../ses.idp.web.api'

type Props = {
  isLoading?: boolean
  items?: IServiceProviderDto[]
  total?: number
  page?: number
  pageSize?: number
  onPageChange?: (page: number) => void
  onSearch?: (value: string) => void
}

export default function ServiceProviderTable({
  items,
  total,
  page,
  pageSize,
  isLoading = false,
  onPageChange,
  onSearch,
}: Props) {
  return (
    <Box mt={4}>
      {onSearch && (
        <SearchBox
          onSearch={onSearch}
          placeholder="Search for Service Providers..."
          mb={4}
        />
      )}
      <Box w="full" overflow="hidden" shadow="md" rounded="lg">
      <Table
        variant="simple"
        minW="full"
        bg="white"
        sx={{
          'tr:hover .actions': {
            visibility: 'visible',
          },
          td: {
            fontSize: 'sm',
            maxW: [14, 16, 24, 40],
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          },
        }}
      >
        <colgroup>
          <col span={1} width="35%" />
          <col span={1} width="60%" />
          <col span={1} width="5%" />
        </colgroup>
        <Thead bg="gray.50">
          <Tr>
            <Th>Name</Th>
            <Th>URL</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.map(p => (
            <Tr key={p.id}>
              <Td>
                <Flex alignItems="center">
                  <Icon
                    as={GrCertificate}
                    visibility={
                      p.signingCertificates?.length > 0 ? 'visible' : 'hidden'
                    }
                    display={
                      !(p.signingCertificates?.length > 0) && {
                        base: 'none',
                        lg: 'initial',
                      }
                    }
                    flexShrink={0}
                    mr="1.5"
                    color="green.500"
                    title="Certificate"
                    // fix a bug in react-icons
                    sx={{
                      path: {
                        stroke: 'currentColor',
                      },
                    }}
                  />
                  <Box>{p.name}</Box>
                </Flex>
              </Td>
              <Td textOverflow="ellipsis" title={p.name}>
                {p.name}
              </Td>
              <Td
                textOverflow="ellipsis"
                title={p.assertionConsumerServices[0].location}
              >
                {p.assertionConsumerServices[0].location}
              </Td>
              <Td isNumeric>
                <a href={`/api/v1/serviceproviders/sso?id=${p.id}`}>
                  <IconButton aria-label="" icon={<FaArrowRight />} />
                </a>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {isLoading && (
        <Stack bg="white" p={3} divider={<Divider />}>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
      {!isLoading && items.length === 0 && (
        <EmptyState label="No service providers" />
      )}
      <Pagination
        showing={items.length}
        total={total}
        perPage={pageSize}
        currentPage={page}
        onPageChange={onPageChange}
      />
      </Box>
    </Box>
  )
}
