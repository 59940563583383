import React from 'react'

import {
  GlobalConfigurationInput,
  useGlobalConfigurations,
} from '../../../libs/hooks'
import utils from '../../../libs/utils'

import RadiusForm from './RadiusForm'

function RadiusConfigurations() {
  const {
    query: { data, isLoading },
    mutation: { mutateAsync },
  } = useGlobalConfigurations()

  const onSubmit = (data: GlobalConfigurationInput) =>
    mutateAsync(data, {
      onSuccess: () =>
        utils.toastSuccess('RADIUS Configuration has been updated'),
      onError: error => utils.toastError(error),
    }).catch(_ => {})

  return <RadiusForm data={data} isLoading={isLoading} onSubmit={onSubmit} />
}

export default RadiusConfigurations
