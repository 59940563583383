import React, { BaseSyntheticEvent, RefObject } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  Input,
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

import { DeleteFidoKeyInput } from '../../../libs/hooks'

interface Props {
  isOpen: boolean
  leastDestructiveRef: RefObject<any>
  onClose: () => void
  onSubmit: (e?: BaseSyntheticEvent) => Promise<void>
}

function DeleteFidoKey({
  isOpen,
  leastDestructiveRef,
  onClose,
  onSubmit,
}: Props) {
  const {
    register,
    watch,
    formState: { isSubmitting },
  } = useFormContext<DeleteFidoKeyInput>()
  const nameWatch = watch('name')

  return (
    <AlertDialog
      leastDestructiveRef={leastDestructiveRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <form onSubmit={onSubmit}>
          <AlertDialogContent>
            <AlertDialogHeader>Delete FIDO Security Key</AlertDialogHeader>
            <AlertDialogBody>
              <p>Are you sure you want to delete '{nameWatch}'?</p>
              <p>You cannot undo this action afterwards.</p>
              <FormControl>
                <Input hidden {...register('id')} />
              </FormControl>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={leastDestructiveRef}
                size="sm"
                variant="ghost"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                size="sm"
                colorScheme="red"
                ml={3}
                isLoading={isSubmitting}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </form>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default DeleteFidoKey
