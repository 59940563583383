import React, { useRef } from 'react'
import { FaPlus } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, Button, Portal, useDisclosure } from '@chakra-ui/react'

import { IUsersToGroupInput } from '../../../ses.idp.web.api'
import {
  ALL_USER_GROUP_ID,
  useAssignUsersToGroup,
  useRemoveUsersFromGroup,
  useUsersOfGroup,
} from '../../../libs/hooks'
import utils from '../../../libs/utils'
import { EmptyState, SectionHeader } from '../../../components'

import UserTable from './UserTable'
import AddUsersModal from './AddUsersModal'
import RemoveUserModal from './RemoveUserModal'

function GroupUsers() {
  const cancelRef = useRef()
  const removeModal = useDisclosure()
  const addModal = useDisclosure()
  const { id } = useParams<{ id: string }>()
  const {
    query: { data, isLoading },
    page,
    setPage,
    setSearch,
  } = useUsersOfGroup(id)
  const { mutateAsync: addAsync } = useAssignUsersToGroup(id, page)
  const { mutateAsync: removeAsync } = useRemoveUsersFromGroup(id, page)
  const defaultValues: IUsersToGroupInput = {
    groupId: Number(id),
    userIds: [],
  }
  const addForm = useForm<IUsersToGroupInput>({ defaultValues })
  const removeForm = useForm<IUsersToGroupInput>({ defaultValues })

  const handleAddSubmit = addForm.handleSubmit(input => {
    return addAsync(input, {
      onError: utils.toastError,
      onSuccess: () => {
        addModal.onClose()
        utils.toastSuccess()
      },
    }).catch(_ => {})
  })

  const handleRemoveSubmit = removeForm.handleSubmit(input => {
    return removeAsync(input, {
      onError: utils.toastError,
      onSuccess: () => {
        removeModal.onClose()
        utils.toastSuccess()
      },
    }).catch(_ => {})
  })

  const handleRemoveClick = (userId: number) => {
    removeForm.setValue('userIds', [userId])
    removeModal.onOpen()
  }

  return (
    <Box>
      <SectionHeader description="Users that are assigned to this group and have access to Service Providers of this group.">
        {id !== ALL_USER_GROUP_ID && (
          <Button
            size="sm"
            colorScheme="blue"
            leftIcon={<FaPlus />}
            onClick={addModal.onOpen}
          >
            Assign users
          </Button>
        )}
      </SectionHeader>
      <UserTable
        total={data?.totalCount ?? 0}
        perPage={20}
        currentPage={page}
        onPageChange={setPage}
        items={data?.items}
        onRemove={id !== ALL_USER_GROUP_ID ? handleRemoveClick : undefined}
        isLoading={isLoading}
        onSearch={setSearch}
        emptyState={
          <EmptyState
            onClick={addModal.onOpen}
            label="Add a User to this Group"
          />
        }
      />
      <Portal>
        <FormProvider {...addForm}>
          <AddUsersModal
            groupId={id}
            onSubmit={handleAddSubmit}
            {...addModal}
          />
        </FormProvider>
        <RemoveUserModal
          isSubmitting={removeForm.formState.isSubmitting}
          leastDestructiveRef={cancelRef}
          onSubmit={handleRemoveSubmit}
          {...removeModal}
        />
      </Portal>
    </Box>
  )
}

export default GroupUsers
