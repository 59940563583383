import { HubConnectionBuilder } from '@microsoft/signalr'

export enum NotificationEvent {
  NONE = 0,
  VERIFIED = 1,
  USER_STATUS = 2,
}

export const notificationHub = new HubConnectionBuilder()
  .withUrl('/hub')
  .build()
