import { HubConnectionState } from '@microsoft/signalr/dist/esm/HubConnection'
import { useCallback, useEffect, useState } from 'react'
import { SectionHeader } from '../../../components'
import { NotificationEvent, notificationHub } from '../../../libs/hooks'
import { useIdpLogs } from '../../../libs/hooks/api/users'
import IdpLogsTable from './IdpLogsTable'

function IdpLogsPage() {
  const [message, setMessage] = useState(new Date().toLocaleString('en-US'))
  const {
    query: { data, isLoading, refetch },
    currentPage,
    setCurrentPage,
  } = useIdpLogs()

  const handlePush = useCallback(
    (event: NotificationEvent) => {
      if (event === NotificationEvent.VERIFIED) {
        setMessage(new Date().toLocaleString('en-US'))
        refetch()
      }
    },
    [refetch],
  )

  useEffect(() => {
    if (!notificationHub) return

    notificationHub.on('push', handlePush)

    if (notificationHub.state === HubConnectionState.Disconnected)
      notificationHub.start()

    return () => {
      notificationHub.off('push', handlePush)
    }
  }, [handlePush])

  return (
    <div>
      <SectionHeader title="IDP Logs" description={`Last updated ${message}`} />
      <IdpLogsTable
        isLoading={isLoading}
        data={data?.items}
        perPage={25}
        total={data?.totalCount ?? 0}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
    </div>
  )
}

export default IdpLogsPage
