import { Redirect, Route, Switch } from 'react-router-dom';
import { useAppState } from '../../libs/hooks';

import Groups from './Groups'
import Group from './Groups/Group'

function Iam() {
    const { user } = useAppState()
    return user.isAdmin ? (
        <Switch>
            <Route path="/iam/groups/:id" component={Group} />
            <Route path="/iam/groups" component={Groups} />
            <Redirect to="/iam/groups" />
        </Switch>
    ) : null;
}

export default Iam
