import React, {
  ChangeEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  Box,
  chakra,
  Checkbox,
  Divider,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { FaTrash } from 'react-icons/fa'

import { IGroupDto } from '../../../ses.idp.web.api'
import { EmptyState, Pagination, SearchBox } from '../../../components'
import { ALL_DEVICE_GROUP_ID, ALL_USER_GROUP_ID } from '../../../libs/hooks'

interface Props {
  items: IGroupDto[] | null | undefined
  isLoading: boolean
  isSingleSelect?: boolean
  total: number
  perPage: number
  currentPage: number
  onPageChange?: (page: number) => void
  onSelect?: (selection: number[]) => void
  onDelete?: (id: number) => void
  onSearch?: (search: string) => void
  emptyState?: ReactNode
}

function GroupTable({
  items = [],
  isLoading,
  isSingleSelect,
  total,
  perPage,
  currentPage,
  onPageChange,
  onDelete,
  emptyState,
  onSelect,
  onSearch,
}: Props) {
  const [selection, setSelection] = useState({} as Record<string, boolean>)

  const handleSelect: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      setSelection(prev => {
        const itemId = e.currentTarget.id
        if (isSingleSelect) {
          return { [itemId]: e.currentTarget.checked }
        }
        return { ...prev, [itemId]: e.currentTarget.checked }
      })
    },
    [isSingleSelect],
  )

  useEffect(() => {
    onSelect && onSelect(Object.keys(selection).map(s => parseInt(s)))
  }, [onSelect, selection])

  return (
    <Box mt={4}>
      {onSearch && (
        <SearchBox
          onSearch={onSearch}
          placeholder="Search for groups..."
          mb={4}
        />
      )}
      <Box w="full" overflow="hidden" shadow="md" rounded="lg">
      <Table
        variant="simple"
        minW="full"
        bg="white"
        sx={{
          'tr:hover .actions': {
            visibility: 'visible',
          },
          td: {
            fontSize: 'sm',
            maxW: [14, 16, 24, 40],
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
          '.group-name': {
            color: 'blue.500',
          },
        }}
      >
        <Thead bg="gray.50">
          <Tr>
            <Th w="25%">Name</Th>
            <Th w="52%">Description</Th>
            <Th w="20%">Date Added</Th>
            <Th w="3%">
              <chakra.span srOnly>Edit</chakra.span>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.map(({ id, displayName, description, dateCreatedUtc }) => (
            <Tr key={id}>
              <Td title={displayName}>
                  <Link
                    className="group-name"
                    to={`/iam/groups/${id}/settings`}
                  >
                  {displayName}
                </Link>
              </Td>
              <Td>
                <span title={description}>{description}</span>
              </Td>
              <Td>
                <span>{dayjs(dateCreatedUtc).format('L LT')}</span>
              </Td>
              <Td>
                {onSelect && (
                  <Checkbox
                    id={String(id)}
                    isChecked={Boolean(selection[id])}
                    onChange={handleSelect}
                  />
                )}
                  {onDelete &&
                    ![ALL_USER_GROUP_ID, ALL_DEVICE_GROUP_ID].includes(
                      `${id}`,
                    ) && (
                    <chakra.span color="red.500" title="Delete">
                      <FaTrash
                        className="actions"
                        cursor="pointer"
                        visibility="hidden"
                        onClick={() => onDelete(id)}
                      />
                    </chakra.span>
                  )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {isLoading && (
        <Stack bg="white" p={3} divider={<Divider />}>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
      {!isLoading &&
        items.length === 0 &&
        (emptyState || <EmptyState label="No groups found" />)}
      <Pagination
        showing={items.length}
        total={total}
        perPage={perPage}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </Box>
    </Box>
  )
}

export default GroupTable
