import React, { BaseSyntheticEvent } from 'react'
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Stack,
} from '@chakra-ui/react'

import { useGroups } from '../../../../libs/hooks'
import GroupTable from '../../../IAM/Groups/GroupTable'

interface Props {
  isOpen: boolean
  isSubmitting: boolean
  isDirty: boolean
  isSingleSelect?: boolean
  setValue: (ids: number[]) => void
  onClose: () => void
  onSubmit: (e?: BaseSyntheticEvent) => Promise<void>
  excludeIds?: number | number[]
}

function AddGroupsModal({
  isOpen,
  onClose,
  isSubmitting,
  isDirty,
  isSingleSelect,
  setValue,
  excludeIds,
  onSubmit,
}: Props) {
  const { items, total, page, setPage, isLoading } = useGroups({
    enabled: isOpen,
    excludeIds,
  })

  return (
    <Drawer size="xl" placement="right" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <form onSubmit={onSubmit}>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader w="full">
            <Stack
              w="full"
              direction={{ base: 'column', sm: 'row' }}
              spacing={3}
              alignItems={{ sm: 'center' }}
            >
              <span aria-label="Add Group">Select a group for alerts</span>
            </Stack>
          </DrawerHeader>
          <DrawerBody>
            <GroupTable
              items={items}
              isLoading={isLoading}
              isSingleSelect={isSingleSelect}
              total={total}
              perPage={20}
              currentPage={page}
              onPageChange={setPage}
              onSelect={setValue}
            />
          </DrawerBody>
          <DrawerFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              ml={4}
              type="submit"
              isLoading={isSubmitting}
              isDisabled={!isDirty}
            >
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  )
}

export default AddGroupsModal
