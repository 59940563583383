import { Link, useParams } from 'react-router-dom'
import { Box, Button } from '@chakra-ui/react'
import { FaArrowLeft } from 'react-icons/fa'

import { TabDescription, useTabs, useGroup } from '../../../libs/hooks'
import { Page } from '../../../components'

import GroupSettings from './GroupSettings'
import GroupUsers from './GroupUsers'
import GroupServiceProviders from './GroupServiceProviders'

const groupTabs: TabDescription[] = [
  {
    path: 'settings',
    route: '/iam/groups/:id/settings',
    label: 'Settings',
    permissions: ['admin'],
    Content: GroupSettings,
  },
  {
    path: 'users',
    route: '/iam/groups/:id/users',
    label: 'Users',
    permissions: ['admin'],
    Content: GroupUsers,
  },
  {
    path: 'saml',
    route: '/iam/groups/:id/saml',
    label: 'SAML',
    permissions: ['admin'],
    Content: () => <GroupServiceProviders variant="SAML" />,
  },
  {
    path: 'oidc',
    route: '/iam/groups/:id/oidc',
    label: 'OIDC',
    permissions: ['admin'],
    Content: () => <GroupServiceProviders variant="OIDC" />,
  },
]

function Group() {
  const tabs = useTabs(groupTabs)
  const { id } = useParams<{ id: string }>()
  const { data } = useGroup(id)

  return (
    <Box
      display="grid"
      gridTemplateRows="auto 1fr"
      gridTemplateColumns="1fr"
      gap="4"
    >
      <div>
        <Box display="grid" gridTemplateColumns="minmax(0px, 1fr)">
          <div>
            <Button
              size="xs"
              color="gray.600"
              variant="ghost"
              leftIcon={<FaArrowLeft />}
              as={Link}
              to="/iam/groups"
            >
              Back to Groups
            </Button>
          </div>
          <Box
            display="flex"
            marginX="2"
            marginY="4"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="flex-start"
            fontSize="2xl"
            fontWeight="bold"
          >
            {data?.displayName || 'Unknown'}
          </Box>
        </Box>
      </div>
      <Page {...tabs} />
    </Box>
  )
}

export default Group
