import {
    Box
} from '@chakra-ui/react'
import { SectionHeader } from '../../../components'
import { useIdpInitiatedServiceProviders } from '../../../libs/hooks'

import ServiceProviderTable from './ServiceProviderTable'

export default function ServiceProviderSection() {
  const { page, setPage, isLoading, items, total, setSearch } =
    useIdpInitiatedServiceProviders()

  return (
    <div>
      <SectionHeader
        title="Service Providers"
        description="List of IdP-initiated SSO"
      />
        <ServiceProviderTable
          items={items}
          total={total}
          page={page}
          pageSize={10}
          onPageChange={setPage}
          isLoading={isLoading}
        onSearch={setSearch}
        />
    </div>
  )
}
