import { useCallback, useEffect } from 'react'
import { HubConnectionState } from '@microsoft/signalr'

import { useOnlineUsers } from '../../../libs/hooks/api/users'
import { NotificationEvent, notificationHub } from '../../../libs/hooks'
import { SectionHeader } from '../../../components'
import OnlineUsersTable from './OnlineUserTable'

export default function OnlineUserSection() {
  const { data, isLoading, refetch } = useOnlineUsers(1)

  const handlePush = useCallback(
    (event: NotificationEvent) => {
      if (event === NotificationEvent.USER_STATUS) {
        refetch()
      }
    },
    [refetch],
  )

  useEffect(() => {
    if (!notificationHub) return

    notificationHub.on('push', handlePush)

    if (notificationHub.state === HubConnectionState.Disconnected)
      notificationHub.start()

    return () => {
      notificationHub.off('push', handlePush)
    }
  }, [handlePush])

  return (
    <div>
      <SectionHeader
        title="Online users"
        description="Users currently logged in MagicEndpoint"
      />
      <OnlineUsersTable isLoading={isLoading} data={data?.items} />
    </div>
  )
}
