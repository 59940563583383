import React from 'react'
import { ButtonProps, chakra, Flex } from '@chakra-ui/react'

export interface EmptyStateProps {
  label: string
  onClick?: ButtonProps['onClick']
}

export function EmptyState({ onClick, label }: EmptyStateProps) {
  return (
    <Flex bg="white" p={8} justifyContent="center">
      <chakra.button
        type="button"
        pos="relative"
        w="sm"
        border="3px"
        borderColor="gray.300"
        borderStyle="dashed"
        borderRadius="lg"
        padding={8}
        textAlign="center"
        _hover={{
          borderColor: 'gray.400',
        }}
        onClick={onClick}
      >
        <chakra.span
          mt={2}
          display="block"
          fontSize="sm"
          fontWeight="medium"
          color="gray.800"
        >
          {label}
        </chakra.span>
      </chakra.button>
    </Flex>
  )
}
