import React from 'react'
import {
  Box,
  Button,
  VStack,
  BoxProps,
  ButtonProps,
  StackProps,
  chakra,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { FaCheckCircle } from 'react-icons/fa'

import { InstallProgress } from '../../libs/hooks'

export const InstallSection = chakra(Box, {
  label: 'install-section',
  baseStyle: {
    display: {
      md: 'grid',
    },
    gridTemplateColumns: {
      md: 'repeat(3,minmax(0,1fr))',
    },
    gridGap: {
      md: 6,
    },
  },
})

export function InstallSectionLabel(props: BoxProps) {
  return (
    <Box gridColumn={{ md: 'span 1/span 1' }}>
      <Box px={{ base: 4, sm: 0 }} {...props} />
    </Box>
  )
}

export function InstallSectionContent(props: StackProps) {
  return (
    <Box mt={{ base: 5, md: 0 }} gridColumn={{ md: 'span 2/span 2' }}>
      <Box shadow="md" rounded={{ sm: 'lg' }} overflow="hidden">
        <VStack p={{ base: 3, sm: 6 }} bg="white" spacing={5} {...props} />
      </Box>
    </Box>
  )
}

const installButtonVariants: Record<keyof typeof InstallProgress, ButtonProps> =
  {
    None: {
      colorScheme: 'blue',
      children: 'Save',
    },
    Installing: {
      colorScheme: 'blue',
      isDisabled: true,
      children: 'Applying configurations...',
    },
    Restarting: {
      colorScheme: 'blue',
      isDisabled: true,
      children: 'Restarting...',
    },
    Failed: {
      colorScheme: 'blue',
      children: 'Save',
    },
    Succeeded: {
      colorScheme: 'green',
      leftIcon: <FaCheckCircle />,
      children: 'Installation succeeded',
    },
  }

const installButtonTransition = {
  type: 'spring',
  stiffness: 700,
  damping: 30,
}

export function InstallButton({
  progress,
  ...props
}: ButtonProps & {
  progress: InstallProgress
}) {
  return (
    <motion.div layout transition={installButtonTransition}>
      <Button
        type="submit"
        variant="solid"
        {...installButtonVariants[progress]}
        {...props}
      />
    </motion.div>
  )
}
