import React, {
  ChangeEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  Box,
  Center,
  chakra,
  Checkbox,
  Divider,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { FaTrash } from 'react-icons/fa'
import { IUserDto } from '../../../ses.idp.web.api'
import { Pagination, SearchBox } from '../../../components'

function UserTable({
  items = [],
  isLoading,
  onRemove,
  onSelect,
  emptyState,
  total,
  perPage,
  currentPage,
  onPageChange,
  onSearch,
}: {
  items: IUserDto[] | null | undefined
  isLoading: boolean
  onRemove?: (id: number) => void
  onSelect?: (selection: number[]) => void
  emptyState?: ReactNode
  total: number
  perPage: number
  currentPage: number
  onPageChange: (page: number) => void
  onSearch?: (value: string) => void
}) {
  const [selection, setSelection] = useState<number[]>([])

  const handleSelect: ChangeEventHandler<HTMLInputElement> = useCallback(e => {
    setSelection(prev => {
      const currentId = Number(e.currentTarget.id)
      if (e.currentTarget.checked) {
        return [...prev, currentId]
      }
      return prev.filter(k => k !== currentId)
    })
  }, [])

  useEffect(() => {
    onSelect && onSelect([...selection])
  }, [onSelect, selection])

  return (
    <Box mt={4}>
      {onSearch && (
        <SearchBox
          onSearch={onSearch}
          placeholder="Search for users..."
          mb={4}
        />
      )}
      <Box w="full" overflow="hidden" shadow="md" rounded="lg">
      <Table
        variant="simple"
        minW="full"
        bg="white"
        sx={{
          'tr:hover .actions': {
            visibility: 'visible',
          },
          td: {
            fontSize: 'sm',
            maxW: [14, 16, 24, 40],
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          },
        }}
      >
        <Thead bg="gray.50">
          <Tr>
            <Th w="25%">Email</Th>
            <Th w="14%">First Name</Th>
            <Th w="58%">Last Name</Th>
            {onRemove && (
              <Th w="3%">
                <chakra.span srOnly>Edit</chakra.span>
              </Th>
            )}
            {onSelect && (
              <Th w="3%">
                <chakra.span srOnly>Select</chakra.span>
              </Th>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {items.map(({ id, email, firstName, lastName }) => (
            <Tr key={id}>
              <Td title={email}>
                <span>{email}</span>
              </Td>
              <Td title={firstName}>
                <span>{firstName}</span>
              </Td>
              <Td title={lastName}>
                <span>{lastName}</span>
              </Td>
              {onRemove && (
                <Td color="red.500" title="Delete">
                  <FaTrash
                    className="actions"
                    cursor="pointer"
                    visibility="hidden"
                    onClick={() => onRemove(id)}
                  />
                </Td>
              )}
              {onSelect && (
                <Td title="Select">
                  <Checkbox id={String(id)} onChange={handleSelect} />
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
      {isLoading && (
        <Stack bg="white" p={3} divider={<Divider />}>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
      {!isLoading && !items.length && (emptyState || <DefaultEmptyState />)}
      <Pagination
        showing={items?.length}
        total={total}
        perPage={perPage}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </Box>
    </Box>
  )
}

function DefaultEmptyState() {
  return (
    <Center p={5} fontSize="sm">
      No users found
    </Center>
  )
}

export default UserTable
