import React from 'react'
import QRCode from 'qrcode.react'
import { css } from '@emotion/react'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  chakra,
  Divider,
  Flex,
  Image,
  Link,
  Spinner,
  Text,
  useBoolean,
  VStack,
} from '@chakra-ui/react'
import { usePrevious } from 'react-use'

import utils from '../../../libs/utils'
import { useMobileRegistration } from '../../../libs/hooks'
import { MobileDeviceStatus } from '../../../ses.idp.web.api'

function MobileConfigurations() {
  const [successOpen, setSuccessOpen] = useBoolean(true)
  const { data, isLoading, override, setOverride } = useMobileRegistration()
  const { status, ...rest } = data || {}
  const prevStatus = usePrevious(status)

  return (
    <VStack css={styles} spacing={10} alignItems="stretch">
      <div>
        <Box w="full">
          <Box display={['block', 'flex']}>
            <Box flex={['none', '1 1 auto']} my={1}>
              <chakra.h1
                fontSize="xl"
                fontWeight="semibold"
                textColor="gray.900"
              >
                Setup WinMagic Authenticator app
              </chakra.h1>
            </Box>
          </Box>
        </Box>
        <Flex flexDir="column" bg="white" shadow="md" rounded="lg" p={8} mt={4}>
          {status === MobileDeviceStatus.Registered &&
            prevStatus !== MobileDeviceStatus.Unregistered &&
            !override && (
              <Alert status="warning">
                <AlertIcon />
                <Box ml={1}>
                  <AlertTitle>
                    A phone is already registered to this account!
                  </AlertTitle>
                  <AlertDescription>
                    MagicEndpoint supports a single phone for authentication. If
                    you proceed, the new phone will replace the previous phone
                    which can no longer be used for authentication.
                  </AlertDescription>
                  <Button
                    ml={3}
                    size="xs"
                    colorScheme="yellow"
                    onClick={() => {
                      setOverride.on()
                      setSuccessOpen.on()
                    }}
                  >
                    Proceed
                  </Button>
                </Box>
              </Alert>
            )}
          {status === MobileDeviceStatus.Registered &&
            prevStatus === MobileDeviceStatus.Unregistered &&
            successOpen && (
              <Alert status="success">
                <AlertIcon />
                <Box ml={1}>
                  <AlertTitle>Registration success!</AlertTitle>
                  <AlertDescription>
                    You have successfully linked a new phone to this account,
                    you can now use it to login to MagicEndpoint.
                  </AlertDescription>
                  <Button
                    ml={3}
                    size="xs"
                    colorScheme="yellow"
                    onClick={setSuccessOpen.off}
                  >
                    Ok
                  </Button>
                </Box>
              </Alert>
            )}
          {(status === MobileDeviceStatus.Unregistered || override) && (
            <VStack spacing={3} alignItems="flex-start" divider={<Divider />}>
              <Box>
                1. Open the app.
                <Text fontSize="sm" color="gray.500">
                  If you do not yet have the app installed in your phone,
                  install it using{' '}
                  <Link color="blue.500" href={'#install'}>
                    the instruction bellow
                  </Link>
                  .
                </Text>
              </Box>
              <Box>
                2. Pair the app with your account by scanning this QR code.
                <Box my={2}>
                  {isLoading && (
                    <Center w={60} h={60} border="1px" borderColor="gray.300">
                      <Spinner />
                    </Center>
                  )}
                  {!isLoading && data && (
                    <QRCode
                      className="pair-qr"
                      value={btoa(utils.stringifyCamelcase(rest))}
                      bgColor="#ffffff"
                      fgColor="#000000"
                      level="L"
                      includeMargin={false}
                      renderAs="svg"
                      imageSettings={{
                        src: '/img/MagicEndpoint.ico',
                        x: null,
                        y: null,
                        height: 20,
                        width: 20,
                        excavate: true,
                      }}
                    />
                  )}
                </Box>
              </Box>
            </VStack>
          )}
        </Flex>
      </div>
      <div>
        <Box w="full">
          <Box display={['block', 'flex']}>
            <Box flex={['none', '1 1 auto']} my={1}>
              <chakra.h1
                id="install"
                fontSize="xl"
                fontWeight="semibold"
                textColor="gray.900"
              >
                Install WinMagic Authenticator app
              </chakra.h1>
            </Box>
          </Box>
        </Box>
        <Flex flexDir="column" bg="white" shadow="md" rounded="lg" p={8} mt={4}>
          <Box flex="1" textAlign="left">
            Install the WinMagic Authenticator app on your phone by scanning the
            QR code below.
          </Box>
          <Box mt={5} display={['block', 'flex']}>
            <Box>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.winmagic.wauth"
              >
                <Image
                  src="/img/google-play-badge.webp"
                  alt="Get it on Google Play"
                  w={44}
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://apps.apple.com/us/app/wmauthenticator/id1545238190"
              >
                <Image
                  src="/img/app-store-badge.webp"
                  alt="Download on the App Store"
                  w={44}
                  mt={6}
                />
              </a>
            </Box>
            <Box ml={[5, 5]} mt={[5, 0]}>
              <QRCode
                value="https://wmauthenticator.page.link/zXbp"
                size={128}
                bgColor="#ffffff"
                fgColor="#000000"
              />
              <span>Scan to Download</span>
            </Box>
          </Box>
        </Flex>
      </div>
    </VStack>
  )
}

const styles = css`
  @media screen and (min-width: 30em) {
    .pair-qr {
      width: var(--chakra-space-60) !important;
      height: var(--chakra-space-60) !important;
    }
  }
`

export default MobileConfigurations
