import { FieldValues, Path, UseFormSetError } from 'react-hook-form'

import { ApiException, UserActionMode } from '../ses.idp.web.api'
import utils from './utils'

export interface IProblemDetails {
  type?: string | undefined
  title?: string | undefined
  status?: number | undefined
  detail?: string | undefined
  instance?: string | undefined
  errors?: Record<string, string[]> | undefined
}

export class ProblemDetails implements IProblemDetails {
  type?: string | undefined
  title?: string | undefined
  status?: number | undefined
  detail?: string | undefined
  instance?: string | undefined
  errors?: Record<string, string[]> | undefined
  isValidationError: boolean

  constructor(data?: IProblemDetails, isValidationError?: boolean) {
    if (data) {
      this.title = data.title
      this.detail = data.detail
      this.instance = data.instance
      this.errors = data.errors
      this.status = data.status
      this.type = data.type || 'https://tools.ietf.org/html/rfc7231'
    }
    this.isValidationError = isValidationError || false
  }

  static parse(data: any): ProblemDetails {
    try {
      if (data && ProblemDetails.isProblemDetails(data)) {
        if (data.errors && Object.keys(data.errors).length) {
          data.isValidationError = true
        }
        return data
      }

      if (data && ApiException.isApiException(data)) {
        if (
          data.headers['content-type']?.includes('application/problem+json')
        ) {
          const pd = JSON.parse(data.response) as ProblemDetails
          if (pd.errors && Object.keys(pd.errors).length) {
            pd.isValidationError = true
          }
          return pd
        }

        return new ProblemDetails({
          status: data.status,
          detail: data.response,
          title: data.message || 'Something went wrong!',
          type: 'https://datatracker.ietf.org/doc/html/rfc7231',
        })
      }
    } catch {}

    return new ProblemDetails({
      status: 500,
      title: isString(data) ? data : 'Something went wrong!',
      detail: data,
      type: 'https://datatracker.ietf.org/doc/html/rfc7231',
    })
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['type'] = this.type
    data['title'] = this.title
    data['status'] = this.status
    data['detail'] = this.detail
    data['instance'] = this.instance
    data['errors'] = this.errors
    return data
  }

  static isValidationError(obj: any): obj is ProblemDetails {
    if (!obj) return false
    const { type, errors, status } = obj
    return type?.includes('rfc7231') && status === 400 && Boolean(errors)
  }

  static isProblemDetails(obj: any): obj is ProblemDetails {
    return obj && obj.type && String(obj.type).includes('rfc7231')
  }

  static setHookFormError<TFieldValues extends FieldValues = FieldValues>(
    setError: UseFormSetError<TFieldValues>,
    problemDetail: ProblemDetails,
  ) {
    const keys = Object.keys(problemDetail.errors)
    keys.forEach(key => {
      const errors = problemDetail.errors[key]
      if (errors && errors.length) {
        setError(utils.camelize(key) as Path<TFieldValues>, {
          message: errors[0],
        })
      }
    })
  }
}

export function isString(value: any): value is string {
  return typeof value === 'string' || value instanceof String
}

export function throwProblemDetails(error: any): any {
  throw ProblemDetails.parse(error)
}

export const UserActionModeLabels: Record<string, string> = {
  null: 'System Default',
  '-1': 'System Default',
  [UserActionMode.Silent]: 'No user action is required',
  [UserActionMode.UserPresence]: 'User provides simple gesture/click',
  [UserActionMode.UserVerification]: 'User must authenticate',
}
