import React, {
  ChangeEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Box,
  Icon,
  Badge,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  Divider,
  Skeleton,
  Center,
  Checkbox,
} from '@chakra-ui/react'
import { FaEdit, FaEllipsisV, FaTrash } from 'react-icons/fa'
import { GrCertificate } from 'react-icons/gr'

import { ServiceProviderDto } from '../../../../ses.idp.web.api'
import { UserActionModeLabels } from '../../../../libs/types'
import { Pagination, SearchBox } from '../../../../components'

interface Props {
  providers: ServiceProviderDto[] | null | undefined
  isLoading: boolean
  total: number
  perPage: number
  currentPage?: number
  onPageChange?: (page: number) => void
  onEdit?: (id: number) => void
  onRemove?: (id: number) => void
  onSelect?: (selection: number[]) => void
  emptyState?: ReactNode
  variant?: 'OIDC' | 'SAML'
  onSearch?: (value: string) => void
}

function ServiceProviderTable({
  providers = [],
  onSelect,
  onEdit,
  onRemove,
  isLoading,
  emptyState,
  total,
  perPage,
  onPageChange,
  onSearch,
  variant = 'SAML',
  currentPage = 1,
}: Props) {
  const [selection, setSelection] = useState<number[]>([])

  const handleSelect: ChangeEventHandler<HTMLInputElement> = useCallback(e => {
    setSelection(prev => {
      const itemId = Number(e.currentTarget.id)
      if (e.currentTarget.checked) {
        return [...prev, itemId]
      }
      return prev.filter(id => id !== itemId)
    })
  }, [])

  useEffect(() => {
    onSelect && onSelect(selection)
  }, [onSelect, selection])

  return (
    <Box mt={4}>
      {onSearch && (
        <SearchBox
          onSearch={onSearch}
          placeholder={`Search for ${variant} Service Providers...`}
          mb={4}
        />
      )}
      <Box w="full" overflow="hidden" shadow="md" rounded="lg">
      <Table
        variant="simple"
        minW="full"
        bg="white"
        sx={{
          'tr:hover .actions': {
            visibility: 'visible',
          },
          td: {
            fontSize: 'sm',
            maxW: [14, 16, 24, 40],
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          },
        }}
      >
        <Thead bg="gray.50">
          <Tr>
            <Th w="15%">
              <Flex>
                <Box display={{ base: 'none', lg: 'block' }} w={3} mr="1.5" />
                Name
              </Flex>
            </Th>
            <Th w="20%" display={{ base: 'none', lg: 'table-cell' }}>
              {variant === 'OIDC' ? 'Client Name' : 'Entity ID'}
            </Th>
            <Th w="40%" display={{ base: 'none', sm: 'table-cell' }}>
              {variant === 'OIDC' ? 'Redirect URL' : 'SSO URL'}
            </Th>
            {variant === 'SAML' && (
              <>
                <Th w="5%" display={{ base: 'none', sm: 'table-cell' }}>
                  Binding
                </Th>
                <Th w="17%">
                  Session <sup>sec</sup>
                </Th>
              </>
            )}
            <Th w="3%">
              <chakra.span srOnly>{onSelect ? 'Select' : 'Edit'}</chakra.span>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {providers.map((p, index) => {
            const {
              id,
              name,
              entityId,
              sessionDurationSec,
              assertionConsumerServices,
              signingCertificates,
              userActionMode,
              redirectUris,
            } = p
            const hasCert = signingCertificates?.length > 0
            const { location, binding } =
                (assertionConsumerServices && assertionConsumerServices[0]) ||
                {}
            const { value: redirectURL } =
              (redirectUris && redirectUris[0]) || {}

            return (
              <Tr key={id || index}>
                <Td
                  w={{ base: 'full', sm: 'auto' }}
                  maxW={{ base: 0, sm: 'none' }}
                  py={4}
                  pl={{ base: 4, sm: 6 }}
                  pr={3}
                  fontSize={'sm'}
                  fontWeight={'medium'}
                  color={'gray.900'}
                  title={p.name}
                >
                  <Flex alignItems="center">
                    <Icon
                      as={GrCertificate}
                      visibility={hasCert ? 'visible' : 'hidden'}
                      display={!hasCert && { base: 'none', lg: 'initial' }}
                      flexShrink={0}
                      mr="1.5"
                      color="green.500"
                      title="Certificate"
                      // fix a bug in react-icons
                      sx={{
                        path: {
                          stroke: 'currentColor',
                        },
                      }}
                    />
                    <Box isTruncated>{name}</Box>
                  </Flex>
                  <chakra.dl fontWeight="normal" display={{ lg: 'none' }}>
                    <chakra.dd
                      noOfLines={1}
                      mt={1}
                      color="gray.700"
                      title={entityId}
                    >
                      {entityId}
                    </chakra.dd>
                    <chakra.dd
                      noOfLines={1}
                      mt={1}
                      color="gray.500"
                      display={{ sm: 'none' }}
                      title={location ?? redirectURL}
                    >
                      {location ?? redirectURL}
                    </chakra.dd>
                    {variant === 'SAML' && (
                      <chakra.dd
                        noOfLines={1}
                        mt={1}
                        color="gray.500"
                        display={{ sm: 'none' }}
                        title={binding}
                      >
                        <Badge colorScheme={getBindingBadgeColor(binding)}>
                          {binding}
                        </Badge>
                      </chakra.dd>
                    )}
                  </chakra.dl>
                </Td>
                <Td
                  display={{ base: 'none', lg: 'table-cell' }}
                  color="gray.500"
                  title={entityId}
                >
                  {entityId}
                </Td>
                <Td
                  display={{ base: 'none', sm: 'table-cell' }}
                  color="blue.500"
                  title={location ?? redirectURL}
                >
                  {location ?? redirectURL}
                </Td>
                {variant === 'SAML' && (
                  <>
                    <Td
                      display={{ base: 'none', sm: 'table-cell' }}
                      color="gray.500"
                      title={binding}
                    >
                      <Badge colorScheme={getBindingBadgeColor(binding)}>
                        {binding}
                      </Badge>
                    </Td>
                    <Td title={sessionDurationSec?.toString()}>
                      <div>{sessionDurationSec || '-'}</div>
                      <chakra.div
                        color="gray.500"
                        title={`User Interaction: ${UserActionModeLabels[userActionMode]}`}
                      >
                        {UserActionModeLabels[userActionMode]}
                      </chakra.div>
                    </Td>
                  </>
                )}
                <Td
                  textAlign="right"
                  fontSize="sm"
                  fontWeight="medium"
                  title="Actions"
                >
                  {onSelect ? (
                    <Checkbox id={String(id)} onChange={handleSelect} />
                  ) : (
                    <Menu>
                      <MenuButton
                        className="actions"
                        visibility="hidden"
                        cursor="pointer"
                        w={3}
                      >
                        <FaEllipsisV />
                      </MenuButton>
                      <MenuList>
                        {onEdit && (
                          <MenuItem
                            icon={<FaEdit />}
                            color="blue.600"
                            onClick={() => onEdit(id)}
                          >
                            Edit
                          </MenuItem>
                        )}
                        {onRemove && (
                          <MenuItem
                            icon={<FaTrash />}
                            color="red.500"
                            onClick={() => onRemove(id)}
                          >
                            Delete
                          </MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  )}
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      {isLoading && (
        <Stack bg="white" p={3} divider={<Divider />}>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
        {!isLoading &&
          !providers.length &&
          (emptyState || <DefaultEmptyState />)}
      <Pagination
        showing={providers?.length}
        total={total}
        perPage={perPage}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </Box>
    </Box>
  )
}

function DefaultEmptyState() {
  return (
    <Center p={5} fontSize="sm">
      No Service Provider found
    </Center>
  )
}

function getBindingBadgeColor(binding: string | null | undefined) {
  return binding && binding.toUpperCase() === 'HTTP-REDIRECT'
    ? 'purple'
    : 'green'
}

export default ServiceProviderTable
