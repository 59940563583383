import { VStack } from '@chakra-ui/react'

import { useServerCapabilities } from '../../../libs/hooks'
import { ServiceProviderConfiguration } from '../SAMPL'
import ServerCapabilitiesForm from '../SAMPL/components/ServerCapabilitiesForm'

function OIDCConfiguration() {
  const { data } = useServerCapabilities()
  return (
    <VStack alignItems="stretch" spacing={10}>
      <ServerCapabilitiesForm variant="oidc" capabilities={data} />
      <ServiceProviderConfiguration variant="OIDC" />
    </VStack>
  )
}

export default OIDCConfiguration
