import React from 'react'
import {
  forwardRef,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useClipboard,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { FaRegCopy } from 'react-icons/fa'

export const CopyableInput = forwardRef<InputProps, 'input'>(
  ({ value, ...props }, ref) => {
    const { hasCopied, onCopy } = useClipboard(value as string)
    return (
      <InputGroup css={buttonStyles}>
        <Input
          isReadOnly={false}
          onChange={noop}
          type="text"
          value={value}
          ref={ref}
          {...props}
        />
        <InputRightElement>
          <IconButton
            aria-label="Copy"
            icon={<FaRegCopy />}
            size="sm"
            colorScheme={hasCopied ? 'green' : 'gray'}
            onClick={onCopy}
          />
        </InputRightElement>
      </InputGroup>
    )
  },
)

function noop() {}

const buttonStyles = css`
  .chakra-input {
    padding-right: 1rem;
  }

  .chakra-input__right-element {
    transition: all 0.4s ease-in;
    width: 3rem;
    opacity: 0;
  }

  &:hover {
    .chakra-input {
      padding-right: 3rem;
    }

    .chakra-input__right-element {
      opacity: 1;
    }
  }
`
