import { useMutation, useQuery } from 'react-query'
import * as Yup from 'yup'

import {
  AuthenticationClient,
  ConfigurationClient,
  FileParameter,
  GlobalConfigurationDto,
  GroupDto,
  IGlobalConfigurationDto,
} from '../../../ses.idp.web.api'
import { ProblemDetails, throwProblemDetails } from '../../types'
import utils from '../../utils'
import { urlPattern } from './common'

export type ServerCapabilities = Record<string, Record<string, string>>
export type GlobalConfigurationInput = Omit<
  IGlobalConfigurationDto,
  'alertGroup'
> & {
  signingCertificate: FileParameter
  signingCertificatePassword?: string
  alertGroupId: number
  alertGroupName: string
}

export const globalConfigurationSchema: Yup.SchemaOf<GlobalConfigurationInput> =
  Yup.object({
    radiusPort: Yup.number().notRequired(),
    radiusTimeout: Yup.number().notRequired(),
    radiusSecret: Yup.string().notRequired(),
    userActionMode: Yup.mixed().notRequired(),
    radiusUserActionMode: Yup.mixed().notRequired(),
    ldapUserActionMode: Yup.mixed().notRequired(),
    radiusEnabled: Yup.boolean().notRequired(),
    radiusMacFilterEnabled: Yup.boolean().notRequired(),
    signingCertificate: Yup.object()
      .shape({
        fileName: Yup.string().notRequired(),
        data: Yup.mixed().notRequired(),
      })
      .nullable()
      .required('Signing Certificate is required'),
    signingCertificatePassword: Yup.string().notRequired(),
    signingCertificateName: Yup.string().notRequired(),
    ldapEnabled: Yup.boolean().notRequired(),
      ldapPort: Yup.number().notRequired(),
      secureDocTcpEnabled: Yup.boolean().notRequired(),
      secureDocTcpPort: Yup.number().notRequired(),
    httpsProxyHostName: Yup.string().notRequired().nullable(),
    alertGroupId: Yup.number().notRequired(),
    alertGroupName: Yup.string().notRequired(),
    smtpFrom: Yup.string().when('smtpLogin', {
      is: (login: string) => !utils.isNullOrWhitespace(login),
      then: Yup.string().trim().required('SMTP From is required'),
    }),
    smtpHost: Yup.string().when('smtpLogin', {
      is: (login: string) => !utils.isNullOrWhitespace(login),
      then: Yup.string().trim().required('SMTP Host is required'),
    }),
    smtpPort: Yup.number().when('smtpLogin', {
      is: (login: string) => !utils.isNullOrWhitespace(login),
      then: Yup.number().min(25, 'Invalid SMTP Port'),
    }),
    smtpLogin: Yup.string().when('smtpPassword', {
      is: (password: string) => !utils.isNullOrWhitespace(password),
      then: Yup.string().trim().required('SMTP Login is required').max(128),
    }),
    smtpPassword: Yup.string().test(
      'pwd',
      'Please enter a valid password',
      function (password: string) {
        const { smtpLogin } = this.parent as GlobalConfigurationInput
        if (password && password.length > 0 && password.trim().length === 0) {
          return false
        }
        return (
          utils.isNullOrWhitespace(smtpLogin) || password.trim().length <= 128
        )
      },
    ),
    deviceCheckInterval: Yup.number()
      .required(
        'Device check interval must be specified, set it to 0 to disable device checking.',
      )
      .transform(v => (isNaN(v) ? undefined : v))
      .min(0, 'Device check interval must not be negative')
      .max(
        1440,
        'Device check interval must not be greater than 1440 minutes (1 day)',
      ),
    sesWebUrl: Yup.string()
      .notRequired()
      .nullable()
      .trim()
      .matches(urlPattern, 'Invalid SESWeb URL'),
  })

export function useGlobalConfigurations() {
  const client = new ConfigurationClient()
  const query = useQuery<IGlobalConfigurationDto, ProblemDetails>(
    'getGlobalConfigurations',
    () => client.getGlobalConfiguration().catch(throwProblemDetails),
    {
      refetchOnWindowFocus: false,
    },
  )
  const mutation = useMutation<void, ProblemDetails, GlobalConfigurationInput>(
    'saveGlobalConfigurations',
    (data: GlobalConfigurationInput) => {
      const updateCert = () =>
        new Promise<void>((resolve, reject) => {
          if (data.signingCertificate?.data) {
            return client
              .updateCertificate(
                data.signingCertificatePassword,
                data.signingCertificate,
              )
              .then(() => resolve())
              .catch(reject)
          }
          resolve()
        })

      return client
        .updateGlobalConfiguration(
          new GlobalConfigurationDto({
            ...data,
            alertGroup:
              data.alertGroupId > 0
                ? new GroupDto({ id: data.alertGroupId })
                : null,
          }),
        )
        .then(updateCert)
        .catch(throwProblemDetails)
    },
    {
      onSuccess: async () => {
        await query.refetch()
      },
    },
  )
  return {
    query,
    mutation,
  }
}

export function useServerCapabilities() {
  return useQuery<ServerCapabilities>(
    'server-configs',
    () => {
      return new AuthenticationClient().serverCapabilities().then(res => {
        const { serverCapabilities } = res
        return serverCapabilities || {}
      })
    },
    { initialData: {} },
  )
}
