import React from 'react'
import {
  Box,
  Button,
  ButtonGroup,
  chakra,
  Divider,
  Flex,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { FaTrash } from 'react-icons/fa'
import dayjs from 'dayjs'

import { IIdpFidoKeyDto } from '../../../ses.idp.web.api'

interface Props {
  data: IIdpFidoKeyDto[] | null | undefined
  isLoading: boolean
  onRegister: () => void
  onDelete: (id: number) => void
}

function FidoKeysTable({ data = [], isLoading, onRegister, onDelete }: Props) {
  return (
    <Box w="full">
      <Box display={['block', 'flex']}>
        <Box flex={['none', '1 1 auto']} my={1}>
          <chakra.h1 fontSize="xl" fontWeight="semibold" textColor="gray.900">
            FIDO Security Keys
          </chakra.h1>
          <chakra.p mt={2} fontSize="sm" textColor="gray.700">
            A list of all of your existing/registered FIDO Security Keys
          </chakra.p>
        </Box>
        <Box my={[4, 0]} ml={[0, 16]} flex={['none', '']}>
          <ButtonGroup isAttached size="sm" variant="solid" colorScheme="blue">
            <Button onClick={onRegister}>Register New Key</Button>
          </ButtonGroup>
        </Box>
      </Box>
      <Box w="full" overflow="hidden" shadow="md" rounded="lg" mt={8}>
        <Table
          variant="simple"
          minW="full"
          bg="white"
          sx={{
            'tr:hover .actions': {
              visibility: 'visible',
            },
            td: {
              fontSize: 'sm',
              maxW: [14, 16, 24, 40],
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            },
          }}
        >
          <Thead bg="gray.50">
            <Tr>
              <Th w="77%">Key</Th>
              <Th w="20%">Date Added</Th>
              <Th w="3%">
                <chakra.span srOnly>Edit</chakra.span>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map(({ id, name, dateCreatedUtc }) => (
              <Tr key={id}>
                <Td title={name} isTruncated>
                  <span>{name}</span>
                </Td>
                <Td>
                  <span>{dayjs(dateCreatedUtc).format('L LT')}</span>
                </Td>
                <Td color="red.500" title="Delete">
                  <FaTrash
                    className="actions"
                    cursor="pointer"
                    visibility="hidden"
                    onClick={() => onDelete(id)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {isLoading && (
          <Stack bg="white" p={3} divider={<Divider />}>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </Stack>
        )}
        {!isLoading && data.length === 0 && (
          <Flex bg="white" p={8} justifyContent="center">
            <chakra.button
              type="button"
              pos="relative"
              w="sm"
              border="3px"
              borderColor="gray.300"
              borderStyle="dashed"
              borderRadius="lg"
              padding={8}
              textAlign="center"
              _hover={{
                borderColor: 'gray.400',
              }}
              onClick={onRegister}
            >
              <chakra.span
                mt={2}
                display="block"
                fontSize="sm"
                fontWeight="medium"
                color="gray.800"
              >
                Register a new FIDO Security Key
              </chakra.span>
            </chakra.button>
          </Flex>
        )}
      </Box>
    </Box>
  )
}

export default FidoKeysTable
