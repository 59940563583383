import { Button, chakra, Icon } from '@chakra-ui/react'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'

import { DOTS, usePagination } from '../libs/hooks/usePagination'
import PaginationInput from './PaginationInput'

export interface PaginationProps {
  total: number
  perPage: number
  currentPage: number
  showing: number
  onPageChange: (page: number) => void
}

export function Pagination(props: PaginationProps) {
  const { total, perPage, currentPage, showing, onPageChange } = props
  const pages = usePagination({
    currentPage,
    total,
    perPage,
  })

  const lastPage = pages[pages.length - 1]
  const minRange = showing === 0 ? 0 : perPage * (currentPage - 1) + 1
  const maxRange = showing === 0 ? 0 : minRange + showing - 1

  const handleNext = () => {
    if (currentPage === lastPage) return
    onPageChange(currentPage + 1)
  }

  const handlePrev = () => {
    if (currentPage < 2) return
    onPageChange(currentPage - 1)
  }

  return (
    <Container>
      <MobileNav>
        <Button
          size="sm"
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
        >
          Previous
        </Button>
        <Button
          size="sm"
          disabled={currentPage === lastPage}
          onClick={() => onPageChange(currentPage + 1)}
        >
          Next
        </Button>
      </MobileNav>
      <DesktopNav>
        <StatWrapper>
          Showing {minRange}-{maxRange} of {total} items
        </StatWrapper>
        <Pages hidden={pages.length < 2}>
          <PageItem
            onPageChange={handlePrev}
            currentPage={currentPage}
            lastPage={lastPage}
            isDisabled={currentPage === 1}
          >
            <Icon as={FaArrowLeft} w={3} h={3} />
          </PageItem>
          {pages.map((num, index) => (
            <PageItem
              key={`${num}_${index}`}
              onPageChange={onPageChange}
              currentPage={currentPage}
              lastPage={lastPage}
            >
              {num}
            </PageItem>
          ))}
          <PageItem
            currentPage={currentPage}
            onPageChange={handleNext}
            lastPage={lastPage}
            isDisabled={currentPage === lastPage}
          >
            <Icon as={FaArrowRight} w={3} h={3} />
          </PageItem>
        </Pages>
      </DesktopNav>
    </Container>
  )
}

const Container = chakra('div', {
  baseStyle: {
    display: 'flex',
    bg: 'gray.50',
    justifyContent: 'space-between',
    alignItems: 'center',
    px: 3,
    py: 3,
  },
})

const MobileNav = chakra('nav', {
  baseStyle: {
    display: ['flex', 'none'],
    flex: 1,
    justifyContent: 'space-between',
  },
})

const DesktopNav = chakra('div', {
  baseStyle: {
    display: ['none', 'flex'],
    alignItems: 'center',
    flex: 1,
    justifyContent: 'space-between',
    fontSize: 'sm',
  },
})

const StatWrapper = chakra('span', {
  baseStyle: {
    display: 'flex',
    alignItems: 'center',
    h: 8,
  },
})

const Pages = chakra('nav', {
  baseStyle: {
    display: 'inline-flex',
  },
})

interface PageItemProps {
  currentPage: number
  lastPage: number
  onPageChange: (index: number) => void
  children?: React.ReactNode
  isDisabled?: boolean
}

function PageItem(props: PageItemProps) {
  const { currentPage, onPageChange, lastPage, children, isDisabled } = props

  const value = typeof children === 'number' ? children : null
  if (children === DOTS) {
    return (
      <PaginationInput
        lastPage={lastPage}
        onPageChange={onPageChange}
      ></PaginationInput>
    )
  }
  return (
    <PageItemStyles
      aria-selected={value === currentPage}
      onClick={() => onPageChange(value || 0)}
      aria-disabled={isDisabled}
    >
      {children}
    </PageItemStyles>
  )
}

const PageItemStyles = chakra('li', {
  baseStyle: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'sm',
    textColor: 'gray.600',
    rounded: 'full',
    w: 8,
    h: 8,
    mx: 1,
    cursor: 'pointer',
    '&:hover': {
      bg: 'gray.100',
    },
    '&[aria-selected="true"]': {
      bg: 'gray.200',
      textColor: 'gray.600',
    },
    '&[aria-disabled="true"]': {
      textColor: 'gray.400',
      cursor: 'not-allowed',
      ':hover': {
        bg: 'gray.50',
      },
    },
  },
})
