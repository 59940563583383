import React from 'react'
import {
  Button,
  FormControl,
  FormLabel,
  VStack,
  chakra,
  CSSObject,
} from '@chakra-ui/react'
import { FaDownload } from 'react-icons/fa'

import { ServerCapabilities } from '../../../../libs/hooks'
import { CopyableInput } from '../../../../components'

const formStyles: CSSObject = {
  '.chakra-form-control': {
    d: { base: 'block', md: 'grid' },
    gridTemplateColumns: { base: 'repeat(3, minmax(0, 1fr))' },
    gap: { md: 4 },
    alignItems: { md: 'flex-start' },
  },
  '.chakra-form__label': {
    textColor: 'gray.700',
    mt: { base: 0, md: 2 },
    mb: 0,
  },
}

function ServerCapabilitiesForm({
  capabilities,
  variant = 'saml',
}: {
  capabilities: ServerCapabilities
  variant?: 'saml' | 'oidc'
}) {
  return (
    <chakra.form w="full">
      <VStack
        spacing={[6, 5]}
        px={4}
        py={5}
        bg="white"
        shadow="md"
        rounded="md"
        overflow="hidden"
        sx={formStyles}
      >
        {variant === 'oidc' && (
          <>
            <FormControl isReadOnly>
              <FormLabel>IdP Issuer URL</FormLabel>
              <chakra.div gridColumn="span 2/span 2">
                <CopyableInput
                  value={capabilities.OIDC?.IdP_Issuer_URI || ''}
                />
              </chakra.div>
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel>IdP OIDC Authorization URL</FormLabel>
              <chakra.div gridColumn="span 2/span 2">
                <CopyableInput
                  value={capabilities.OIDC?.IdP_AUTHORIZATION_URL || ''}
                />
              </chakra.div>
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel>IdP OIDC Token URL</FormLabel>
              <chakra.div gridColumn="span 2/span 2">
                <CopyableInput value={capabilities.OIDC?.IdP_TOKEN_URL || ''} />
              </chakra.div>
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel>IdP OIDC User info URL</FormLabel>
              <chakra.div gridColumn="span 2/span 2">
                <CopyableInput
                  value={capabilities.OIDC?.IdP_USERINFO_URL || ''}
                />
              </chakra.div>
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel>IdP OIDC JWKS URL</FormLabel>
              <chakra.div gridColumn="span 2/span 2">
                <CopyableInput value={capabilities.OIDC?.IdP_JWKS_URL || ''} />
              </chakra.div>
            </FormControl>
          </>
        )}
        {variant === 'saml' && (
          <>
            <FormControl isReadOnly>
              <FormLabel>IdP Issuer URL</FormLabel>
              <chakra.div gridColumn="span 2/span 2">
                <CopyableInput
                  value={capabilities.SAML?.IdP_Issuer_URI || ''}
                />
              </chakra.div>
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel>IdP SAML SSO URL</FormLabel>
              <chakra.div gridColumn="span 2/span 2">
                <CopyableInput value={capabilities.SAML?.IdP_SSO_URL || ''} />
              </chakra.div>
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel>IdP SAML SLO URL</FormLabel>
              <chakra.div gridColumn="span 2/span 2">
                <CopyableInput value={capabilities.SAML?.IdP_SLO_URL || ''} />
              </chakra.div>
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel>IdP WSFed SSO URL</FormLabel>
              <chakra.div gridColumn="span 2/span 2">
                <CopyableInput value={capabilities.WSFed?.IdP_SSO_URL || ''} />
              </chakra.div>
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel>IdP WSFed SLO URL</FormLabel>
              <chakra.div gridColumn="span 2/span 2">
                <CopyableInput value={capabilities.WSFed?.IdP_SLO_URL || ''} />
              </chakra.div>
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel>IdP WSTrust Username URL</FormLabel>
              <chakra.div gridColumn="span 2/span 2">
                <CopyableInput
                  value={capabilities.WSTrust?.Username_URL || ''}
                />
              </chakra.div>
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel mt={0} mb={0}>
                Signing Certificate
              </FormLabel>
              <chakra.div gridColumn="span 2/span 2">
                <Button
                  size="sm"
                  as="a"
                  href="/api/v1/ServiceProviders/DownloadCertificate"
                  leftIcon={<FaDownload />}
                >
                  Download
                </Button>
              </chakra.div>
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel mt={0} mb={0}>
                SAML Metadata
              </FormLabel>
              <chakra.div gridColumn="span 2/span 2">
                <Button
                  size="sm"
                  as="a"
                  href="/api/v1/ServiceProviders/DownloadSamlMetadataDocument"
                  leftIcon={<FaDownload />}
                >
                  Download
                </Button>
              </chakra.div>
            </FormControl>
          </>
        )}
      </VStack>
    </chakra.form>
  )
}

export default ServerCapabilitiesForm
