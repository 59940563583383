import React from 'react'

import { TabDescription, useTabs } from '../../libs/hooks'
import { Page } from '../../components'

import SAMLConfiguration from './SAMPL'
import RadiusConfigurations from './RADIUS'
import LdapConfiguration from './LDAP'
import GlobalConfiguration from './Global'
import MobileConfigurations from './Mobile'
import FidoKeysConfiguration from './FidoKeys'
import OIDCConfiguration from './OIDC'

const configTabs: TabDescription[] = [
  {
    route: '/configuration/sp',
    label: 'SAML SPs',
    Content: SAMLConfiguration,
    permissions: ['admin'],
  },
  {
    route: '/configuration/oidc',
    label: 'OIDC',
    Content: OIDCConfiguration,
    permissions: ['admin'],
  },
  {
    route: '/configuration/radius',
    label: 'RADIUS',
    Content: RadiusConfigurations,
    permissions: ['admin'],
  },
  {
    route: '/configuration/ldap',
    label: 'LDAP',
    Content: LdapConfiguration,
    permissions: ['admin'],
  },
  {
    route: '/configuration/global-settings',
    label: 'Global',
    Content: GlobalConfiguration,
    permissions: ['admin'],
  },
  {
    route: '/configuration/fido',
    label: 'FIDO Security Keys',
    Content: FidoKeysConfiguration,
    permissions: [],
  },
  {
    route: '/configuration/mobile',
    label: 'Mobile',
    Content: MobileConfigurations,
    permissions: [],
  },
]

function Configurations() {
  const tabs = useTabs(configTabs)
  return <Page {...tabs} />
}

export default Configurations
