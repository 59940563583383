import React, { createContext, useMemo } from 'react'

import { AppUser, useIsInstall, useMe } from '../../libs/hooks'
import Loading from '../Loading'

interface IAppStateContext {
  user: AppUser
  isInstalled: boolean
}

export const AppStateContext = createContext<IAppStateContext>({
  user: null,
  isInstalled: true,
})

const AppStateContextProvider = AppStateContext.Provider

interface AppStateProviderProps {
  children: React.ReactNode
}

export function AppStateProvider({ children }: AppStateProviderProps) {
  const { isInstalled, isInstallLoading } = useIsInstall()
  const { user, isLoadingUser } = useMe()

  const value: IAppStateContext = useMemo(
    () => ({ user, isInstalled }),
    [user, isInstalled],
  )

  return (
    <AppStateContextProvider value={value}>
      <Loading isLoading={isInstallLoading || isLoadingUser}>
        {children}
      </Loading>
    </AppStateContextProvider>
  )
}
