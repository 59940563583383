import { useMemo } from 'react'

export const NUMBER_OF_PAGE_TO_SHOW = 7
export const DOTS = 0

function range(start: number, end: number) {
  return Array.from({ length: end - start + 1 }, (_, i) => i + start)
}

interface PaginationConfig {
  total: number
  perPage: number
  currentPage: number
}

export function usePagination(config: PaginationConfig) {
  const { total, perPage, currentPage } = config
  return useMemo(() => {
    const totalPage = Math.ceil(total / perPage)
    if (totalPage <= NUMBER_OF_PAGE_TO_SHOW) {
      return range(1, totalPage)
    }

    const leftPage = Math.max(1, currentPage - 1)
    const rightPage = Math.min(totalPage, currentPage + 1)

    const showLeftDots = leftPage > 2
    const showRightDots = totalPage - currentPage > 2

    if (showLeftDots && showRightDots) {
      return [1, DOTS, ...range(leftPage, rightPage), DOTS, totalPage]
    }

    if (showRightDots && !showLeftDots) {
      return [...range(1, 5), DOTS, totalPage]
    }

    if (!showRightDots && showLeftDots) {
      return [1, DOTS, ...range(totalPage - 4, totalPage)]
    }
  }, [currentPage, perPage, total])
}
