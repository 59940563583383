import React from 'react'
import {
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  Stack,
  VStack,
  Flex,
  Switch,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react'
import { Controller, useFormContext } from 'react-hook-form'

import { FileInput, PasswordInput } from '../../../../components'
import { UserActionMode } from '../../../../ses.idp.web.api'
import { UserActionModeLabels } from '../../../../libs/types'
import { GlobalConfigurationInput } from '../../../../libs/hooks'

interface Props {
  isLoading: boolean
  onAlertGroupClick: () => void  
}

function GlobalSettingsForm({ isLoading, onAlertGroupClick }: Props) {
  const {
    register,
    control,
    setValue,
    formState: { isSubmitting, errors, isDirty, dirtyFields },
    watch,    
  } = useFormContext<GlobalConfigurationInput>()
  const cert = watch('signingCertificate')
  const alertGroup = watch('alertGroupName')
  const isTcpEnabled =watch('secureDocTcpEnabled')

  return (
    <Box w="full">
      <Box display={['block', 'flex']}>
        <Box flex={['none', '1 1 auto']} my={1}>
          <chakra.h1 fontSize="xl" fontWeight="semibold" textColor="gray.900">
            Global Configuration
          </chakra.h1>
        </Box>
        {isDirty && (
          <Box my={[4, 0]} ml={[0, 16]} flex={['none', '']}>
            <Button
              type="submit"
              variant="solid"
              colorScheme="blue"
              size="sm"  
              isLoading={isSubmitting}            
            >
              Save
            </Button>
          </Box>
        )}
      </Box>
      <VStack spacing={5} bg="white" shadow="md" rounded="lg" p={8} mt={4}>
        <FormControl isInvalid={Boolean(errors.userActionMode)}>
          <FormLabel htmlFor="name">How user will confirm presence</FormLabel>
          <Select
            id="name"
            {...register('userActionMode', { valueAsNumber: true })}
          >
            <option value={UserActionMode.Silent}>
              {UserActionModeLabels[UserActionMode.Silent]}
            </option>
            <option value={UserActionMode.UserPresence}>
              {UserActionModeLabels[UserActionMode.UserPresence]}
            </option>
            <option value={UserActionMode.UserVerification}>
              {UserActionModeLabels[UserActionMode.UserVerification]}
            </option>
          </Select>
          <FormErrorMessage>
            {errors.userActionMode && errors.userActionMode.message}
          </FormErrorMessage>
          <FormHelperText>
            Note: Settings defined above may be overridden by Service
            Provider-specific settings which will alter your user experience.
          </FormHelperText>
        </FormControl>
        <FormControl isInvalid={Boolean(errors.signingCertificate)}>
          <FormLabel>Signing Certificate</FormLabel>
          <FileInput
            control={control}
            name="signingCertificate"
            accept=".pfx"
          />
          <FormErrorMessage>
            {(errors.signingCertificate as any)?.message &&
              certErrorMessages[(errors.signingCertificate as any).message]}
          </FormErrorMessage>
        </FormControl>
        {dirtyFields.signingCertificate && cert !== null && (
          <FormControl isInvalid={Boolean(errors.signingCertificatePassword)}>
            <FormLabel>Signing Certificate Password</FormLabel>
            <PasswordInput {...register('signingCertificatePassword')} />
            <FormErrorMessage>
              {errors.signingCertificatePassword?.message}
            </FormErrorMessage>
          </FormControl>
        )}
        <FormControl>
          <FormLabel>Alert Group</FormLabel>
          <InputGroup>
            <Input
              {...register('alertGroupName')}
              pr="4.5rem"
              onClick={onAlertGroupClick}
            />
            {alertGroup && (
              <InputRightAddon width="4.5rem">
                <Button
                  variant="unstyled"
                  h="1.75rem"
                  size="sm"
                  onClick={() => {
                    setValue('alertGroupName', '', { shouldDirty: true })
                    setValue('alertGroupId', 0, { shouldDirty: true })
                  }}
                >
                  Clear
                </Button>
              </InputRightAddon>
            )}
          </InputGroup>
          <FormHelperText>
            The group that will receive alerts in case there is an attack.
          </FormHelperText>
        </FormControl>
        <Stack w="full" direction={{ base: 'column', md: 'row' }}>
          <FormControl isInvalid={Boolean(errors.smtpLogin)}>
            <FormLabel>SMTP Login</FormLabel>
            <Input {...register('smtpLogin')} />
            <FormErrorMessage>{errors.smtpLogin?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={Boolean(errors.smtpPassword)}>
            <FormLabel>SMTP Password</FormLabel>
            <Input type="password" {...register('smtpPassword')} />
            <FormErrorMessage>{errors.smtpPassword?.message}</FormErrorMessage>
          </FormControl>
        </Stack>
        <Stack w="full" direction={{ base: 'column', md: 'row' }}>
          <FormControl isInvalid={Boolean(errors.smtpFrom)}>
            <FormLabel>SMTP From</FormLabel>
            <Input {...register('smtpFrom')} />
            <FormErrorMessage>{errors.smtpFrom?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={Boolean(errors.smtpHost)}>
            <FormLabel>SMTP Host</FormLabel>
            <Input {...register('smtpHost')} />
            <FormErrorMessage>{errors.smtpHost?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={Boolean(errors.smtpPort)}>
            <FormLabel>SMTP Port</FormLabel>
            <Select {...register('smtpPort', { valueAsNumber: true })}>
              {smtpPorts.map(p => (
                <option key={p} value={p}>
                  {p}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.smtpPort?.message}</FormErrorMessage>
          </FormControl>
        </Stack>
        <FormControl isInvalid={Boolean(errors.deviceCheckInterval)}>
          <FormLabel>Device check interval (min)</FormLabel>
          <NumberInput maxW={32} min={0} max={1440}>
            <NumberInputField {...register('deviceCheckInterval')} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <FormErrorMessage>
            {errors.deviceCheckInterval?.message}
          </FormErrorMessage>
          <FormHelperText>
            The time interval, in minutes, to request fresh device
            authentication status automatically. You can put '0' to disable automatic refresh.
          </FormHelperText>
        </FormControl>
        <FormControl isInvalid={Boolean(errors?.sesWebUrl)}>
          <FormLabel>SESWeb URL</FormLabel>
          <Input {...register("sesWebUrl")} />
          <FormErrorMessage>
            {errors?.sesWebUrl?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl display="flex" alignItems="center">
        <Flex>
          <FormLabel>Enabled SecureDoc TCP</FormLabel>
          <Switch           
            key={`secureDocTcp-Enabled-${isLoading}`}        
            {...register('secureDocTcpEnabled')}
          />
        </Flex>
      </FormControl>
        <Stack w="full" direction={{ base: 'column', md: 'row' }}>
          <FormControl
            isInvalid={Boolean(errors.secureDocTcpPort)}
            hidden={!isTcpEnabled}
          >
        <FormLabel>SDConnex Port</FormLabel>
        <Controller
          control={control}
          name="secureDocTcpPort"
          rules={{
            required: 'Port is required',
            min: {
              value: 1,
              message: 'Port number must be between 1 and 65535',
            },
            max: {
              value: 65535,
              message: 'Port number must be between 1 and 65535',
            },
          }}
          render={({ field: { ref, ...restField } }) => (
            <NumberInput {...restField}>
              <NumberInputField ref={ref} name={restField.name} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          )}
        />
        <FormErrorMessage>
          {errors.secureDocTcpPort && errors.secureDocTcpPort.message}
        </FormErrorMessage>
      </FormControl>
          <FormControl
            isInvalid={Boolean(errors.httpsProxyHostName)}
            hidden={!isTcpEnabled}
          >
            <FormLabel>SDConnex HostName</FormLabel>
            <Input {...register('httpsProxyHostName')} readOnly />            
          </FormControl>
        </Stack>
      </VStack>
    </Box>
  )
}

const smtpPorts = [25, 465, 587, 2525]

const certErrorMessages = {
  no_private_key: "The certificate doesn't container a private key",
  expired: 'The certificate is expired',
} as Record<string, string>

export default GlobalSettingsForm
