import React from 'react'
import { Box, Button, Flex, Heading, Img } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'

import { LoginInput } from '../../libs/hooks'

const copyright = `© 2021-${new Date().getFullYear()} WinMagic Inc. [${
  process.env.REACT_APP_BUILD_VERSION || '0.0.0.1-dev'
}]`

interface AuthFormProps {
  onSubmit: (input: LoginInput) => Promise<unknown>
}

function AuthForm({ onSubmit }: AuthFormProps) {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm()
  const submit = handleSubmit(() => onSubmit(null).catch(_ => {}))

  return (
    <Flex
      minH="100vh"
      flexDir="column"
      justifyContent="center"
      px={{ sm: 6 }}
      py={{ base: 12, lg: 8 }}
    >
      <Box mt={8} mx={{ sm: 'auto' }} w={{ sm: 'full' }} maxW={{ sm: 'md' }}>
        <Box
          bg="white"
          overflow="hidden"
          py={16}
          px={{ base: 4, sm: 10 }}
          shadow="md"
          rounded={{ sm: 'xl' }}
        >
          <Flex alignItems="center" justifyContent="flex-start">
            <Img h={12} w={12} src="/favicon.ico" />
            <Box>
              <Heading
                ml={3}
                size="sm"
                textAlign="center"
                textColor="gray.800"
                fontWeight="semibold"
              >
                MagicEndpoint Identity Provider
              </Heading>
              <Heading
                size="sm"
                ml={3}
                mt={1}
                textColor="gray.500"
                fontWeight="normal"
              >
                Sign in to your account
              </Heading>
            </Box>
          </Flex>
          <form onSubmit={submit}>
            <Flex justifyContent="center" alignItems="center" mt={12}>
              <Button
                isFullWidth
                type="submit"
                colorScheme="blue"
                isLoading={isSubmitting}
              >
                Sign in
              </Button>
            </Flex>
          </form>
        </Box>
        <Flex justifyContent="center" pt={4} fontSize="sm" textColor="gray.500">
          {copyright}
        </Flex>
      </Box>
    </Flex>
  )
}

export default AuthForm
