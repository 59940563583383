import React from 'react'
import { Flex, Spinner } from '@chakra-ui/react'

interface LoadingProps {
  isLoading: boolean
  children: React.ReactNode
}

function Loading({ isLoading, children }: LoadingProps) {
  if (isLoading) {
    return (
      <Flex
        w="100vw"
        h="100vh"
        pos="fixed"
        zIndex={1000}
        justifyContent="center"
        alignItems="center"
        transition="opacity 1s, visibility -0.3s linear 1s"
      >
        <Spinner />
      </Flex>
    )
  }

  return <>{children}</>
}

export default Loading
