import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'

import {
  IInstallationDto,
  InstallationClient,
  InstallationDto,
} from '../../../ses.idp.web.api'
import { ProblemDetails, throwProblemDetails } from '../../types'

const QK_IS_INSTALL = 'isInstalled'
const QK_CURRENT_INSTALL = 'currentInstallation'
const MK_INSTALL = 'install'

export enum InstallProgress {
  None = 'None',
  Installing = 'Installing',
  Restarting = 'Restarting',
  Succeeded = 'Succeeded',
  Failed = 'Failed',
}

export function useIsInstall() {
  const { data, isLoading } = useQuery<boolean>(
    QK_IS_INSTALL,
    () => {
      return new InstallationClient().isInstalled().catch(() => false)
    },
    {
      initialData: true,
      refetchOnWindowFocus: false,
    },
  )
  return {
    isInstalled: data,
    isInstallLoading: isLoading,
  }
}

export function useCurrentInstallation() {
  const { data, isLoading } = useQuery<IInstallationDto>(
    QK_CURRENT_INSTALL,
    () => {
      return new InstallationClient().get().catch(() => new InstallationDto())
    },
    {
      refetchOnWindowFocus: false,
    },
  )

  return {
    installation: data,
    isInstallationLoading: isLoading,
  }
}

function poll() {
  return new Promise<InstallProgress>(resolve => {
    const it = setInterval(() => {
      new InstallationClient()
        .get()
        .then(() => {
          clearInterval(it)
          resolve(InstallProgress.Succeeded)
        })
        .catch(() => InstallProgress.Restarting)
    }, 1000)
  })
}

export function useInstall() {
  const [progress, setProgress] = useState(InstallProgress.None)
  const mutation = useMutation<
    InstallProgress,
    ProblemDetails,
    IInstallationDto
  >(
    MK_INSTALL,
    async (input: IInstallationDto) => {
      const client = new InstallationClient()

      const data = new FormData()
      Object.entries(input).forEach(([k, v]) => {
        data.append(k, v)
      })

      const response = await fetch('/api/v1/installation', {
        body: data,
        method: 'post',
      })
      if (!response.ok) {
        const error = await response.json()
        throwProblemDetails(error)
      }

      const delay = () => new Promise(resolve => setTimeout(resolve, 2000))
      await delay()

      setProgress(InstallProgress.Restarting)
      await client.restartWebService()

      try {
        return await poll()
      } catch {
        // ignore
      }
    },
    {
      onMutate: () => {
        setProgress(InstallProgress.Installing)
      },
      onError: () => {
        setProgress(InstallProgress.Failed)
      },
      onSuccess: () => {
        setProgress(InstallProgress.Succeeded)
      },
    },
  )

  return {
    mutation,
    progress,
  } as const
}
