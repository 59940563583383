import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useAppState } from './app'

export interface TabDescription {
  route: string
  path?: string
  label: string
  Content: () => JSX.Element
  permissions: string[] | null
}

export function useTabs(tabs: TabDescription[]) {
  const { user } = useAppState()
  const { pathname } = useLocation()

  const { permissions } = user || {}
  const permittedTabs = useMemo(() => {
    if (!permissions) {
      return tabs
    }
    return tabs.filter(
      t =>
        !t.permissions ||
        t.permissions.length === 0 ||
        t.permissions.every(p => permissions.includes(p)),
    )
  }, [permissions, tabs])

  const tabIndex = useMemo(() => {
    return permittedTabs.findIndex(t => pathname.endsWith(t.path || t.route))
  }, [pathname, permittedTabs])

  return {
    tabs: permittedTabs,
    currentTabIndex: tabIndex,
  } as const
}
