import React, { BaseSyntheticEvent } from 'react'
import {
  Drawer,
  Button,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Stack,
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

import { ServiceProviderInputType } from '../../../../libs/hooks'
import AddServiceProviderForm from './AddServiceProviderForm'

function AddServiceProviderModal({
  isOpen,
  onClose,
  onSubmit,
}: {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  onSubmit: (e?: BaseSyntheticEvent) => Promise<void>
}) {
  const {
    watch,
    formState: { isSubmitting, isDirty },
  } = useFormContext<ServiceProviderInputType>()

  const watchFormType = watch('formType')

  return (
    <Drawer size="xl" placement="right" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <form onSubmit={onSubmit}>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader w="full">
            <Stack
              w="full"
              direction={{ base: 'column', sm: 'row' }}
              spacing={3}
              alignItems={{ sm: 'center' }}
            >
              <span aria-label="Add Service Provider">
                {formLabels[watchFormType]}
              </span>
            </Stack>
          </DrawerHeader>
          <DrawerBody>
            <AddServiceProviderForm />
          </DrawerBody>
          <DrawerFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              ml={4}
              type="submit"
              isLoading={isSubmitting}
              isDisabled={!isDirty}
            >
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  )
}

const formLabels = {
  add: 'Add Service Provider',
  edit: 'Edit Service Provider',
  import: 'Import Service Provider',
}

export default AddServiceProviderModal
