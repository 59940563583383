import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Switch,
  VStack,
} from '@chakra-ui/react'
import React from 'react'
import { useFormContext } from 'react-hook-form'

import { IGroupDto } from '../../../ses.idp.web.api'

interface Props {
  children?: React.ReactNode
}

function GroupForm({ children }: Props) {
  const {
    register,
    formState: { errors },
  } = useFormContext<IGroupDto>()

  return (
    <VStack spacing={5}>
      <FormControl isRequired isInvalid={Boolean(errors.displayName)}>
        <FormLabel>Name</FormLabel>
        <Input
          placeholder="Enter a name to identify this Group"
          {...register('displayName', {
            maxLength: {
              value: 100,
              message: 'Name must be less than 100 characters',
            },
            minLength: {
              value: 1,
              message: 'Name is required',
            },
          })}
        />
        <FormErrorMessage>
          {errors.displayName && errors.displayName.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isRequired isInvalid={Boolean(errors.description)}>
        <FormLabel>Description</FormLabel>
        <Input
          placeholder="A short text to describe this group"
          {...register('description')}
        />
        <FormErrorMessage>
          {errors.description && errors.description.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl>
        <Flex>
          <FormLabel title="Allow out-of-band authentication">
            Allow OOB
          </FormLabel>
          <Switch {...register('allowOob')} />
        </Flex>
      </FormControl>
      {children}
    </VStack>
  )
}

export default GroupForm
