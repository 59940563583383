import React, { useRef } from 'react'
import {
  Box,
  Button,
  ButtonGroup,
  Portal,
  useDisclosure,
} from '@chakra-ui/react'
import { FaPlus } from 'react-icons/fa'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { IGroupDto } from '../../../ses.idp.web.api'
import { ProblemDetails } from '../../../libs/types'
import utils from '../../../libs/utils'
import { groupFormSchema, useGroups } from '../../../libs/hooks'

import AddGroupModal from './AddGroupModal'
import GroupTable from './GroupTable'
import DeleteGroupModal from './DeleteGroupModal'
import { EmptyState, SectionHeader } from '../../../components'

function Groups() {
  const cancelRef = useRef()
  const addModal = useDisclosure()
  const deleteModal = useDisclosure()
  const addForm = useForm<IGroupDto>({
    resolver: yupResolver(groupFormSchema),
    defaultValues: {
      id: 0,
      displayName: '',
      description: '',
      allowOob: true,
      dateCreatedUtc: new Date(),
    },
  })
  const deleteForm = useForm<{ id: number }>()
  const {
    items,
    total,
    page,
    setPage,
    setSearch,
    isLoading,
    deleteAsync,
    createAsync,
  } = useGroups({})

  const handleAdd = addForm.handleSubmit(input => {
    return createAsync(input, {
      onSuccess: data => {
        utils.toastSuccess(`Group '${data.displayName}' has been created!`)
        addForm.reset()
        addModal.onClose()
      },
      onError: error => {
        if (error.isValidationError) {
          ProblemDetails.setHookFormError(addForm.setError, error)
        } else {
          utils.toastError(error)
        }
      },
    }).catch(_ => {})
  })

  const handleDelete = deleteForm.handleSubmit(({ id }) => {
    return deleteAsync(id, {
      onError: utils.toastError,
      onSuccess: () => {
        const g = items.find(g => g.id === id)
        utils.toastSuccess(`Group '${g.displayName}' has been deleted!`)
        deleteModal.onClose()
      },
    }).catch(_ => {})
  })

  const handleDeleteModalOpen = (id: number) => {
    deleteForm.setValue('id', id)
    deleteModal.onOpen()
  }

  return (
    <Box>
      <SectionHeader
        title="Groups"
        description="Create and manage Groups. Groups let you control users' access to Service Providers."
      >
        <ButtonGroup isAttached size="sm" variant="solid" colorScheme="blue">
          <Button
            leftIcon={<FaPlus />}
            borderRight="1px"
            borderRightColor="whiteAlpha.800"
            onClick={addModal.onOpen}
          >
            Create group
          </Button>
        </ButtonGroup>
      </SectionHeader>
      <GroupTable
        total={total}
        perPage={10}
        currentPage={page}
        items={items}
        isLoading={isLoading}
        onPageChange={setPage}
        onDelete={handleDeleteModalOpen}
        onSearch={setSearch}
        emptyState={
          <EmptyState onClick={addModal.onOpen} label="Add a new group" />
        }
      />
      <Portal>
        <FormProvider {...deleteForm}>
          <DeleteGroupModal
            leastDestructiveRef={cancelRef}
            onSubmit={handleDelete}
            {...deleteModal}
          />
        </FormProvider>
        <FormProvider {...addForm}>
          <AddGroupModal onSubmit={handleAdd} {...addModal} />
        </FormProvider>
      </Portal>
    </Box>
  )
}

export default Groups
