import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useFilePicker } from 'use-file-picker'
import { useController } from 'react-hook-form'
import { useDropArea } from 'react-use'

import { FileParameter } from '../../ses.idp.web.api'
import SelectedFile from './SelectedFile'
import FilePicker from './FilePicker'

export interface FileInputProps {
  control: any
  name: string
  accept?: string | string[]
  showContent?: boolean
  description?: string
}

export function FileInput({
  control,
  name,
  accept,
  showContent,
  description,
}: FileInputProps) {
  const {
    field: { onChange, value },
  } = useController({ control, name, defaultValue: null })
  const shouldUpdateRef = useRef(false)
  const [selectedFile, setSelectedFile] = useState<FileParameter>()
  const [openFileSelector, { filesContent, plainFiles, loading, clear }] =
    useFilePicker({
      accept,
      readAs: 'Text',
      multiple: false,
    })

  const [dndBond] = useDropArea({
    onFiles: (files) => {
      const droppedFile = files[0]
      if (droppedFile) {
        onChange({
          fileName: droppedFile.name,
          data: droppedFile,
        })
        setSelectedFile({
          fileName: droppedFile.name,
          data: '', // todo
        })
      }
    },
  })

  const pickedFile = filesContent[0]
  const pickedFileData = plainFiles[0]

  useEffect(() => {
    if (!shouldUpdateRef.current) {
      shouldUpdateRef.current = true
      return
    }
    if (pickedFile) {
      onChange({
        fileName: pickedFile.name,
        data: pickedFileData,
      })
      setSelectedFile({
        fileName: pickedFile.name,
        data: pickedFile.content,
      })
    }
  }, [onChange, pickedFile, pickedFileData])

  const onDelete = useCallback(() => {
    clear()
    onChange(null)
    setSelectedFile(null)
  }, [clear, onChange])

  if (loading) {
    return <div>Loading...</div>
  }

  if (selectedFile || value) {
    return (
      <SelectedFile
        showContent={showContent}
        file={selectedFile || value}
        onDelete={onDelete}
      />
    )
  }

  return (
    <div {...dndBond}>
      <FilePicker description={description} onClick={openFileSelector} />
    </div>
  )
}
