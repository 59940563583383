import { useState } from 'react'
import { useQuery } from 'react-query'
import { throwProblemDetails } from '../../types'

export interface IOnlineUserDto {
  id: number
  username: string
  firstName?: string
  lastName?: string
  deviceName?: string
}

export interface IPagedResultDtoOfOnlineUsersDto {
  totalCount?: number
  items?: IOnlineUserDto[]
}

export function useOnlineUsers(page: number) {
  return useQuery<IPagedResultDtoOfOnlineUsersDto>(
    ['getOnlineUsers', page],
    () => {
      return fetch('/api/v1/users/onlineUsers')
        .then(res => res.json() as IPagedResultDtoOfOnlineUsersDto)
        .catch(throwProblemDetails)
    },
  )
}

export interface IIdpLogsDto {
  id: number
  email: string
  serviceProvider?: string
  message?: string
  dateCreated: Date
}

export interface IPagedResultDtoOfIdpLogsDto {
  totalCount?: number
  items?: IIdpLogsDto[]
}

export function useIdpLogs(page?: number) {
  const [currentPage, setCurrentPage] = useState(page || 1)
  const query = useQuery<IPagedResultDtoOfIdpLogsDto>(
    ['getIdpLogs', currentPage],
    () => {
      return fetch(`/api/v1/users/idplogs?page=${currentPage}&pageSize=25`)
        .then(res => res.json() as IPagedResultDtoOfIdpLogsDto)
        .catch(throwProblemDetails)
    },
  )
  return {
    query,
    currentPage,
    setCurrentPage,
  } as const
}
