import { useEffect, useState } from 'react'
import { useBoolean } from '@chakra-ui/react'
import { useQuery } from 'react-query'

import {
  IMobileRegistrationDto,
  MobileClient,
  MobileDeviceStatus,
} from '../../../ses.idp.web.api'
import { ProblemDetails, throwProblemDetails } from '../../types'

const QK_MOBILE_REGISTRATION = 'mobileRegistration'

export function useMobileRegistration() {
  const [override, setOverride] = useBoolean()
  const [status, setStatus] = useState(MobileDeviceStatus.Unregistered)

  useEffect(() => {
    setOverride.off()
  }, [status, setOverride])

  const query = useQuery<IMobileRegistrationDto, ProblemDetails>(
    [QK_MOBILE_REGISTRATION, override],
    async function () {
      try {
        const result = await new MobileClient().getMobileRegistration(override)
        setStatus(result.status)
        return result
      } catch (e) {
        throwProblemDetails(e)
      }
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: status === MobileDeviceStatus.Unregistered ? 3000 : 0,
    },
  )

  return { ...query, override, setOverride } as const
}
