import React, { useCallback, useEffect } from 'react'
import { Portal, useDisclosure } from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { ProblemDetails } from '../../../libs/types'
import utils from '../../../libs/utils'
import {
  GlobalConfigurationInput,
  globalConfigurationSchema,
  useGlobalConfigurations,
} from '../../../libs/hooks'

import GlobalSettingsForm from './components/GlobalSettingsForm'
import AddGroupsModal from './components/AddGroupsModal'

function GlobalConfiguration() {
  const groupsModal = useDisclosure()

  const {
    query: { data, isLoading },
    mutation: { mutateAsync },
  } = useGlobalConfigurations()

  const form = useForm<GlobalConfigurationInput>({
    resolver: yupResolver(globalConfigurationSchema),
  })
  const {
    setValue,
    formState: { isDirty, isSubmitting },
  } = form

  const onSubmit = form.handleSubmit(data => {
    return mutateAsync(data, {
      onSuccess: () => {
        utils.toastSuccess('Global Configuration has been updated')
        groupsModal.onClose()
      },
      onError: error => {
        if (error.isValidationError) {
          ProblemDetails.setHookFormError(form.setError, error)
        } else {
          utils.toastError(error)
        }
      },
    }).catch(_ => {})
  })

  const onSelectGroup = useCallback(
    (selection: number[]) => {
      setValue('alertGroupId', selection[0] || 0, { shouldDirty: true })
    },
    [setValue],
  )

  useEffect(() => {
    data &&
      form.reset({
        ...data,
        signingCertificate: {
          fileName: data.signingCertificateName || 'MainSignature',
          data: null,
        },
        signingCertificatePassword: '',
        alertGroupId: data.alertGroup?.id || 0,
        alertGroupName: data.alertGroup?.displayName || '',
        smtpFrom: data.smtpFrom || '',
        smtpHost: data.smtpHost || '',
        smtpLogin: data.smtpLogin || '',
        smtpPassword: data.smtpPassword || '',
        smtpPort: data.smtpPort || 0,
      })
  }, [data, form])

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <GlobalSettingsForm onAlertGroupClick={groupsModal.onOpen} isLoading={isLoading} />
      </form>
      <Portal>
        <AddGroupsModal
          {...groupsModal}
          isSingleSelect
          isSubmitting={isSubmitting}
          isDirty={isDirty}
          excludeIds={data?.alertGroup?.id}
          setValue={onSelectGroup}
          onSubmit={onSubmit}
        />
      </Portal>
    </FormProvider>
  )
}

export default GlobalConfiguration
