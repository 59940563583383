import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import * as Yup from 'yup'

import {
  FileParameter,
  IServiceProviderDto,
  ServiceProvidersClient,
  IAssertionConsumerServiceDto,
  ISingleLogoutServiceDto,
  ServiceProviderDto,
  AssertionConsumerServiceDto,
  SingleLogoutServiceDto,
  UserActionMode,
  CertificateDto,
  IPagedResultDtoOfServiceProviderDto,
  IRedirectUriDto,
  ISecretDto,
  RedirectUriDto,
  SecretDto,
  IScopeDto,
  ScopeDto,
  IAttributeMappingDto,
  AttributeMappingDto,
} from '../../../ses.idp.web.api'
import utils from '../../utils'
import { ProblemDetails, throwProblemDetails } from '../../types'
import { urlPattern } from './common'

export type ServiceProviderInputType = Pick<
  IServiceProviderDto,
  | 'id'
  | 'name'
  | 'entityId'
  | 'sessionDurationSec'
  | 'subjectIdJwtClaimType'
  | 'userActionMode'
  | 'requirePkce'
  | 'idpInitiated'
  | 'relayState'
> & {
  formType: 'add' | 'edit' | 'import'
  assertionConsumerServices: IAssertionConsumerServiceDto[]
  singleLogoutServices: ISingleLogoutServiceDto[]
  redirectUris: IRedirectUriDto[]
  clientSecrets: ISecretDto[]
  allowedScopes: IScopeDto[]
  attributeMappings: IAttributeMappingDto[]
  signingCertificate?: FileParameter
  // for XML parsing
  xmlFile?: any
  variant?: ServiceProviderVariant
}

export const addServiceProviderSchema: Yup.SchemaOf<ServiceProviderInputType> =
  Yup.object({
    formType: Yup.mixed().notRequired(),
    id: Yup.number().notRequired(),
    name: Yup.string()
      .trim()
      .min(1, 'must be at least 1 non whitespace character')
      .required('The service provider name is required'),
    entityId: Yup.string()
      .trim()
      .min(1, 'must be at least 1 non whitespace character')
      .required('The Entity ID is required'),
    relayState: Yup.string().notRequired().nullable(),
    assertionConsumerServices: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number().notRequired(),
          binding: Yup.string().notRequired(),
          location: Yup.string()
            .trim()
            .matches(urlPattern, 'Invalid SSO URL')
            .required('The SSO URL is required'),
        }),
      )
      .nullable(),
    idpInitiated: Yup.boolean().notRequired().nullable(),
    singleLogoutServices: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().notRequired(),
        binding: Yup.string().notRequired(),
        location: Yup.string()
          .notRequired()
          .nullable()
          .trim()
          .matches(urlPattern, 'Invalid SLO URL'),
      }),
    ),
    redirectUris: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().notRequired(),
        description: Yup.string().notRequired().nullable(),
        value: Yup.string()
          .notRequired()
          .nullable()
          .trim()
          .matches(urlPattern, 'Invalid Redirect URL'),
      }),
    ),
    clientSecrets: Yup.array().of(
      Yup.object()
        .shape({
          id: Yup.number().notRequired(),
          description: Yup.string().notRequired().nullable(),
          expiration: Yup.mixed().notRequired(),
          type: Yup.string().notRequired().nullable(),
          value: Yup.string().trim().nullable().required(),
        })
        .test(
          'clientSecret',
          'Please enter a valid secret',
          function (secret?: ISecretDto) {
            const { variant } = this.parent as ServiceProviderInputType
            return (
              variant === 'SAML' || !utils.isNullOrWhitespace(secret?.value)
            )
          },
        ),
    ),
    allowedScopes: Yup.array().of(
      Yup.object()
        .shape({
          id: Yup.number().notRequired(),
          name: Yup.string().trim().nullable().notRequired(),
        })
        .test(
          'allowedScopes',
          'Please enter allowed scopes',
          function (scopes?: IScopeDto) {
            const { variant } = this.parent as ServiceProviderInputType
            return variant === 'SAML' || !utils.isNullOrWhitespace(scopes?.name)
          },
        ),
    ),
    requirePkce: Yup.boolean().nullable().notRequired(),
    subjectIdJwtClaimType: Yup.string().notRequired(),
    userActionMode: Yup.mixed<UserActionMode>().notRequired(),
    signingCertificate: Yup.object()
      .shape({
        fileName: Yup.string().notRequired(),
        data: Yup.mixed().notRequired(),
      })
      .nullable(),
    sessionDurationSec: Yup.number()
      .typeError('Session exp. must be a valid number')
      .positive('Session exp. must be a positive number')
      .max(utils.maxInt32, 'Session exp. is too large')
      .required(),
    xmlFile: Yup.mixed().notRequired(),
    variant: Yup.mixed().notRequired(),
    attributeMappings: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number().nullable().notRequired(),
          source: Yup.string().required(),
          targetName: Yup.string().nullable().notRequired(),
          targetNameFormat: Yup.string().nullable().notRequired(),
        }),
      )
      .notRequired()
      .nullable(),
  })

export function getDefaultAttributeMap(): IAttributeMappingDto[] {
  return [
    {
      id: 0,
      source: 'email',
      targetName: '',
      targetNameFormat: '',
    },
    {
      id: 0,
      source: 'upn',
      targetName: '',
      targetNameFormat: '',
    },
    {
      id: 0,
      source: 'firstName',
      targetName: '',
      targetNameFormat: '',
    },
    {
      id: 0,
      source: 'lastName',
      targetName: '',
      targetNameFormat: '',
    },
    {
      id: 0,
      source: 'displayName',
      targetName: '',
      targetNameFormat: '',
    },
    {
      id: 0,
      source: 'groupSID',
      targetName: '',
      targetNameFormat: '',
    },
    {
      id: 0,
      source: 'groupName',
      targetName: '',
      targetNameFormat: '',
    },
  ]
}

export const DefaultAttributeMapDisplay: Record<string, string> = {
  email: 'Email',
  upn: 'UPN',
  firstName: 'First Name',
  lastName: 'Last Name',
  displayName: 'Display Name',
  groupSID: 'Group SID',
  groupName: 'Group Name',
}

export const serviceProviderPageIndex = new Map<number, number>()

export type ServiceProviderVariant = 'SAML' | 'OIDC'
export const QK_GET_SERVICE_PROVIDERS = 'getServiceProviders'
export const MK_DELETE_SERVICE_PROVIDER = 'deleteServiceProvider'
export const MK_ADD_SERVICE_PROVIDER = 'addServiceProvider'
export const MK_PARSE_SERVICE_PROVIDER = 'parseServiceProviderFromXml'

export function useServiceProviders({
  variant = 'SAML',
  page = 1,
}: {
  variant?: ServiceProviderVariant
  page?: number
}) {
  const [search, setSearch] = useState('')
  const query = useQuery<IPagedResultDtoOfServiceProviderDto>(
    [QK_GET_SERVICE_PROVIDERS, variant, page],
    () => {
      return new ServiceProvidersClient()
        .get(variant, null, null, null, search, page, 10)
        .catch(throwProblemDetails)
    },
    {
      onSuccess: data => {
        data.items.forEach(item => {
          serviceProviderPageIndex.set(item.id, page)
        })
      },
    },
  )
  return { query, search, setSearch } as const
}

export function useIdpInitiatedServiceProviders() {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')

  const { data, isLoading } = useQuery<IPagedResultDtoOfServiceProviderDto>(
    [QK_GET_SERVICE_PROVIDERS + 'IDP_INITIATED', page, search],
    () => {
      return new ServiceProvidersClient()
        .get('SAML', null, null, true, search, page, 10)
        .catch(throwProblemDetails)
    },
    {
      onSuccess: data => {
        data.items.forEach(item => {
          serviceProviderPageIndex.set(item.id, page)
        })
      },
    },
  )

  return {
    items: data?.items || [],
    total: data?.totalCount ?? 0,
    isLoading,
    page,
    setPage,
    search,
    setSearch,
  } as const
}

export function useParseSpFromXml() {
  return useMutation<IServiceProviderDto, ProblemDetails, FileList>(
    MK_PARSE_SERVICE_PROVIDER,
    (files: FileList) => {
      const params: FileParameter[] = Array.from(files).map(p => ({
        fileName: p.name,
        data: p,
      }))
      return new ServiceProvidersClient()
        .parseXml(params[0])
        .catch(throwProblemDetails)
    },
  )
}

async function validateCert(certData: FileParameter) {
    if (!certData || !(certData.data instanceof Blob)) {
      return
    }

  const client = new ServiceProvidersClient()
  await client.addCertificate(-1, certData.fileName, certData)
}

function addCert(sp: IServiceProviderDto, certData: FileParameter) {
  return new Promise<IServiceProviderDto>((resolve, reject) => {
    if (!certData || !certData.data || !certData.fileName) {
      return resolve(sp)
    }

    if (!(certData.data instanceof Blob)) {
      sp.signingCertificates = [new CertificateDto({ name: certData.fileName })]
      return resolve(sp)
    }

    return new ServiceProvidersClient()
      .addCertificate(sp.id, certData.fileName, certData)
      .then(newCert => {
        sp.signingCertificates = [newCert]
        resolve(sp)
      })
      .catch(err => reject(ProblemDetails.parse(err)))
  })
}

function addFromXml(xml: FileParameter, payload: ServiceProviderDto) {
  const client = new ServiceProvidersClient()
  return new Promise<IServiceProviderDto>(async (resolve, reject) => {
    let tempSp: ServiceProviderDto = null
    try {
      tempSp = await client.addFromXml({
        data: xml,
        fileName: 'metadata.xml',
      })

      const sp = await client.update(
        new ServiceProviderDto({
          ...payload,
          id: tempSp.id,
        }),
      )

      resolve(sp)
    } catch (err) {
      if (tempSp) {
        await client.delete(tempSp.id)
      }
      reject(err)
    }
  })
}

export function useAddServiceProvider(
  variant: 'SAML' | 'OIDC',
  currentPage?: number,
) {
  const queryClient = useQueryClient()
  return useMutation(
    [MK_ADD_SERVICE_PROVIDER, variant],
    async (data: ServiceProviderInputType) => {
      utils.removeEmptyFields(data)
      const { variant, formType, signingCertificate, ...rest } = data
      const payload = new ServiceProviderDto({
        ...rest,
        assertionConsumerServices: data.assertionConsumerServices
          ?.map(p => new AssertionConsumerServiceDto(p))
          .filter(p => p.location && p.binding),
        singleLogoutServices: data.singleLogoutServices
          ?.map(p => new SingleLogoutServiceDto(p))
          .filter(p => p.location && p.binding),
        userActionMode: data.userActionMode < 0 ? null : data.userActionMode,
        redirectUris: data.redirectUris
          ?.map(p => new RedirectUriDto(p))
          .filter(p => !utils.isNullOrWhitespace(p.value)),
        clientSecrets: data.clientSecrets
          ?.map(p => new SecretDto(p))
          .filter(p => !utils.isNullOrWhitespace(p.value)),
        allowedScopes: data.allowedScopes
          ?.map(p => new ScopeDto(p))
          .filter(p => !utils.isNullOrWhitespace(p.name)),
        attributeMappings: data.attributeMappings
          ?.map(p => new AttributeMappingDto(p))
          .filter(p => !utils.isNullOrWhitespace(p.targetName)),
      })

      payload.signingCertificates = !signingCertificate ? [] : null

      const client = new ServiceProvidersClient()
      await validateCert(signingCertificate)

      if (formType === 'import') {
        const result = await addFromXml(data.xmlFile, payload)
        await addCert(result, signingCertificate)
        return result
      }
      if (formType === 'add') {
        const result = await client.add(payload)
        await addCert(result, signingCertificate)
        return result
      }
      if (formType === 'edit') {
        await client.update(payload)
        await addCert(payload, signingCertificate)
        return payload
      }
      return payload
    },
    {
      onSuccess: () => {
        return queryClient.refetchQueries([
          QK_GET_SERVICE_PROVIDERS,
          variant,
          currentPage,
        ])
      },
    },
  )
}

export function useDeleteServiceProvider(variant: ServiceProviderVariant) {
  const queryClient = useQueryClient()
  return useMutation<boolean, ProblemDetails, number>(
    [MK_DELETE_SERVICE_PROVIDER],
    (id: number) =>
      new ServiceProvidersClient().delete(id).catch(throwProblemDetails),
    {
      onSuccess: async (isDeleted, deletedId) => {
        if (!isDeleted) return

        const page = serviceProviderPageIndex.get(deletedId)
        if (!page) return

        return queryClient.resetQueries([
          QK_GET_SERVICE_PROVIDERS,
          variant,
          page,
        ])
      },
    },
  )
}
