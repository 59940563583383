import React, { BaseSyntheticEvent, RefObject } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

function DeleteSAMPLModal({
  onClose,
  onSubmit,
  ...props
}: {
  isOpen: boolean
  leastDestructiveRef: RefObject<any>
  onOpen: () => void
  onClose: () => void
  onSubmit: (e?: BaseSyntheticEvent) => Promise<void>
}) {
  const {
    formState: { isSubmitting },
  } = useFormContext<{ id: number }>()

  return (
    <AlertDialog onClose={onClose} {...props}>
      <AlertDialogOverlay>
        <form onSubmit={onSubmit}>
          <AlertDialogContent>
            <AlertDialogHeader>
              Service Provider to be deleted belongs to Access Group(s)
            </AlertDialogHeader>
            <AlertDialogBody>
              The service provider to be deleted belongs to one or more Access
              Groups. Deleting it will also remove the Provider from those
              Access Groups. Proceed with deletion?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button size="sm" variant="ghost" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                size="sm"
                colorScheme="red"
                ml={3}
                isLoading={isSubmitting}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </form>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default DeleteSAMPLModal
