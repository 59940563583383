import {
  Box,
  Divider,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'

import { EmptyState } from '../../../components'
import { IOnlineUserDto } from '../../../libs/hooks/api/users'


interface Props {
  data?: IOnlineUserDto[]
  isLoading: boolean
}

function OnlineUsersTable({ data = [], isLoading }: Props) {
  return (
    <Box w="full" overflow="hidden" shadow="md" rounded="lg" mt={8}>
      <Table
        variant="simple"
        minW="full"
        bg="white"
        sx={{
          'tr:hover .actions': {
            visibility: 'visible',
          },
          td: {
            fontSize: 'sm',
            maxW: [14, 16, 24, 40],
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          },
        }}
      >
        <Thead bg="gray.50">
          <Tr>
            <Th w="25%">Username</Th>
            <Th w="25%">First Name</Th>
            <Th w="25%">Last Name</Th>
            <Th w="25%">Device Name</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map(({ id, username, firstName, lastName, deviceName }) => (
            <Tr key={id}>
              <Td title={username}>
                <span>{username}</span>
              </Td>
              <Td>
                <span>{firstName}</span>
              </Td>
              <Td>
                <span>{lastName}</span>
              </Td>
              <Td>
                <span>{deviceName}</span>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {isLoading && (
        <Stack bg="white" p={3} divider={<Divider />}>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
      {!isLoading && data.length === 0 && (
        <EmptyState label="No users online" />
      )}
    </Box>
  )
}

export default OnlineUsersTable;