import React, { ChangeEventHandler } from 'react'
import { chakra, Spinner } from '@chakra-ui/react'
import { FaFileUpload } from 'react-icons/fa'

function XmlFileUploadButton({
  onChange,
  isLoading,
}: {
  isLoading: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
}) {
  return (
    <chakra.label
      display="flex"
      alignItems="center"
      cursor="pointer"
      gap={3}
      title="Import SAML Metadata XML"
    >
      <input
        hidden
        id="import"
        type="file"
        accept=".xml"
        multiple={false}
        onChange={onChange}
        onClick={e => {
          e.currentTarget.value = null
        }}
      />
      <chakra.span color="blue.500">
        {isLoading ? <Spinner w={3} h={3} /> : <FaFileUpload />}
      </chakra.span>
      Import from XML
    </chakra.label>
  )
}

export default XmlFileUploadButton
