import { useState } from 'react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Stack,
  ButtonGroup,
  Button,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/react'
import FocusLock from 'react-focus-lock'
import React from 'react'

function PaginationInput(props: {
  lastPage: number
  onPageChange: (value: number) => void
}) {
  const { onOpen, onClose, isOpen } = useDisclosure()
  const firstFieldRef = React.useRef(null)
  const [data, setData] = useState('')
  const { lastPage, onPageChange } = props

  const isError = Number(data) < 1 || Number(data) > lastPage
  return (
    <>
      <Popover
        isOpen={isOpen}
        initialFocusRef={firstFieldRef}
        onOpen={onOpen}
        onClose={onClose}
        placement="right"
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <Button size="sm">...</Button>
        </PopoverTrigger>
        <PopoverContent p={5}>
          <FocusLock returnFocus persistentFocus={false}>
            <PopoverArrow />
            <Stack spacing={4}>
              <FormControl isInvalid={isError}>
                <FormLabel>Select Page</FormLabel>
                <NumberInput min={1} max={lastPage} keepWithinRange={false}>
                  <NumberInputField
                    padding={'auto'}
                    marginBottom={'auto'}
                    id="number"
                    ref={firstFieldRef}
                    onChange={e => setData(e.currentTarget.value)}
                  />
                </NumberInput>
                <ButtonGroup display="flex" justifyContent="flex-end">
                  {isError ? (
                    <FormErrorMessage marginTop={'10px'} alignItems={'center'}>
                      Range: 1 - {lastPage}
                    </FormErrorMessage>
                  ) : null}
                  <Button
                    marginTop={'10px'}
                    variant="outline"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    marginTop={'10px'}
                    onClick={() =>
                      (Number(data) < lastPage + 1 && Number(data)) > 0
                        ? onPageChange(Number(data))
                        : null
                    }
                  >
                    Go
                  </Button>
                </ButtonGroup>
              </FormControl>
            </Stack>
          </FocusLock>
        </PopoverContent>
      </Popover>
    </>
  )
}

export default PaginationInput
