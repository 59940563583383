import { ReactNode } from 'react'
import { Redirect } from 'react-router-dom'

import { useAppState } from '../libs/hooks'

interface ProtectedRoutesProps {
  loginPath: string
  children: ReactNode
}

function ProtectedRoutes({ loginPath, children }: ProtectedRoutesProps) {
  const { user } = useAppState()

  if (!user) {
    return <Redirect to={loginPath} />
  }

  return <>{children}</>
}

export default ProtectedRoutes
