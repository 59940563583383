import React from 'react'
import { Box, Container } from '@chakra-ui/react'

import NavMenu from './NavMenu'

interface LayoutProps {
  children: React.ReactNode
}

function Layout({ children }: LayoutProps) {
  return (
    <div>
      <Box minH="full">
        <NavMenu />
        <Container maxW="container.xl" py={10}>
          {children}
        </Container>
      </Box>
    </div>
  )
}

export default Layout
