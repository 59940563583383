import React, { BaseSyntheticEvent, RefObject } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'

function DeleteGroupModal({
  onClose,
  onSubmit,
  ...props
}: {
  isOpen: boolean
  leastDestructiveRef: RefObject<any>
  onOpen: () => void
  onClose: () => void
  onSubmit: (e?: BaseSyntheticEvent) => Promise<void>
}) {
  const {
    formState: { isSubmitting },
  } = useFormContext<{ id: number }>()

  return (
    <AlertDialog onClose={onClose} {...props}>
      <AlertDialogOverlay>
        <form onSubmit={onSubmit}>
          <AlertDialogContent>
            <AlertDialogHeader>Delete Group</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this Group?
              <br />
              You can't undo this action afterwards.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button size="sm" variant="ghost" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                size="sm"
                colorScheme="red"
                ml={3}
                isLoading={isSubmitting}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </form>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default DeleteGroupModal
