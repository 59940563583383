import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Switch,
  VStack,
} from '@chakra-ui/react'

import { GlobalConfigurationInput } from '../../../libs/hooks'
import { UserActionMode } from '../../../ses.idp.web.api'
import { UserActionModeLabels } from '../../../libs/types'

interface Props extends UseFormReturn<GlobalConfigurationInput> {
  isLoading: boolean
}

function LdapForm({
  isLoading,
  register,
  control,
  formState: { errors },
}: Props) {
  return (
    <VStack spacing={5} bg="white" shadow="md" rounded="lg" p={8} mt={4}>
      <FormControl>
        <Flex>
          <FormLabel>Enabled</FormLabel>
          <Switch
            key={`ldap-enabled-${isLoading}`}
            {...register('ldapEnabled')}
          />
        </Flex>
      </FormControl>
      <FormControl isInvalid={Boolean(errors.ldapPort)}>
        <FormLabel>Port</FormLabel>
        <Controller
          control={control}
          name="ldapPort"
          rules={{
            required: 'Port is required',
            min: {
              value: 1,
              message: 'Port number must be between 1 and 65535',
            },
            max: {
              value: 65535,
              message: 'Port number must be between 1 and 65535',
            },
          }}
          render={({ field: { ref, ...restField } }) => (
            <NumberInput {...restField}>
              <NumberInputField ref={ref} name={restField.name} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          )}
        />
        <FormErrorMessage>
          {errors.ldapPort && errors.ldapPort.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl>
        <FormLabel>How user will confirm presence</FormLabel>
        <Select {...register('ldapUserActionMode', { valueAsNumber: true })}>
          <option value={UserActionMode.UserPresence}>
            {UserActionModeLabels[UserActionMode.UserPresence]}
          </option>
          <option value={UserActionMode.UserVerification}>
            {UserActionModeLabels[UserActionMode.UserVerification]}
          </option>
        </Select>
      </FormControl>
    </VStack>
  )
}

export default LdapForm
