import React from 'react'
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { FileInput, PasswordInput } from '../../../../components'
import utils from '../../../../libs/utils'
import { UserActionModeLabels } from '../../../../libs/types'
import {
  DefaultAttributeMapDisplay,
  ServiceProviderInputType,
} from '../../../../libs/hooks'
import { UserActionMode } from '../../../../ses.idp.web.api'

function AddServiceProviderForm() {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext<ServiceProviderInputType>()
  const { fields: attributeMappingFields } = useFieldArray({
    control,
    name: 'attributeMappings',
  })
  const variant = watch('variant')

  const isSAML = variant === 'SAML'
  const isOIDC = variant === 'OIDC'

  return (
    <VStack spacing={5}>
      <FormControl isRequired isInvalid={Boolean(errors.name)}>
        <FormLabel>Service Provider (SP) Name</FormLabel>
        <Input
          placeholder="Enter a name to identify this Service Provider"
          {...register('name')}
        />
        <FormErrorMessage>
          {errors.name && errors.name.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isRequired isInvalid={Boolean(errors.entityId)}>
        <FormLabel>
          {variant === 'OIDC' ? 'Client Name' : 'Audience URL (SP Entity ID)'}
        </FormLabel>
        <Input
          placeholder="The identifier for the relying party"
          {...register('entityId')}
        />
        <FormErrorMessage>
          {errors.entityId && errors.entityId.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl>
        <FormLabel>Relay State</FormLabel>
        <Input
          type="text"
          placeholder="Relay State"
          {...register('relayState')}
        />
        {/* <FormErrorMessage></FormErrorMessage> */}
      </FormControl>
      {isSAML && (
        <>
          <FormControl
            isRequired
            isInvalid={Boolean(errors.assertionConsumerServices)}
          >
            <FormLabel>SSO URL (Assertion Consumer)</FormLabel>
            <InputGroup>
              <InputLeftAddon px={0}>
                <Select
                  variant="filled"
                  defaultValue="HTTP-POST"
                  border={0}
                  {...register('assertionConsumerServices.0.binding')}
                >
                  <option value="HTTP-POST">POST</option>
                  <option value="HTTP-Redirect">REDIRECT</option>
                </Select>
              </InputLeftAddon>
              <Input
                placeholder="The URL where the identity provider will post back the SAML response"
                {...register('assertionConsumerServices.0.location')}
              />
            </InputGroup>
            <FormErrorMessage>
              {errors.assertionConsumerServices &&
                errors.assertionConsumerServices[0]?.location?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={Boolean(errors.singleLogoutServices)}>
            <FormLabel>SLO URL (Logout Service)</FormLabel>
            <InputGroup>
              <InputLeftAddon px={0}>
                <Select
                  variant="filled"
                  defaultValue="HTTP-POST"
                  border={0}
                  _focus={{ outline: 'none' }}
                  {...register('singleLogoutServices.0.binding')}
                >
                  <option value="HTTP-POST">POST</option>
                  <option value="HTTP-Redirect">REDIRECT</option>
                </Select>
              </InputLeftAddon>
              <Input
                placeholder="Specifies the URL of the single logout service at the Service Provider"
                {...register('singleLogoutServices.0.location', {
                  setValueAs: v => (utils.isNullOrWhitespace(v) ? null : v),
                })}
              />
            </InputGroup>
            <FormErrorMessage>
              {errors.singleLogoutServices &&
                errors.singleLogoutServices[0]?.location?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl display="flex" isInvalid={Boolean(errors.idpInitiated)}>
            <FormLabel>IdP-initiated SSO</FormLabel>
            <Switch {...register('idpInitiated')} />
          </FormControl>
        </>
      )}

      {isOIDC && (
        <>
          <FormControl isInvalid={Boolean(errors.redirectUris)}>
            <FormLabel>Redirect URL</FormLabel>
            <Input {...register('redirectUris.0.value')} />
            <FormErrorMessage>
              {errors.redirectUris && errors.redirectUris[0]?.value?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={Boolean(errors.clientSecrets)}>
            <FormLabel>Client Secret</FormLabel>
            <PasswordInput {...register('clientSecrets.0.value')} />
            <FormErrorMessage>
              {errors.clientSecrets &&
                (errors.clientSecrets[0] as any)?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={Boolean(errors.allowedScopes)}>
            <FormLabel>Allowed Scopes</FormLabel>
            <Input {...register('allowedScopes.0.name')} />
            <FormErrorMessage>
              {errors.allowedScopes &&
                (errors.allowedScopes[0] as any)?.message}
            </FormErrorMessage>
          </FormControl>
        </>
      )}
      <Stack w="full" direction={{ base: 'column', sm: 'row' }} spacing={3}>
        <FormControl isRequired isInvalid={Boolean(errors.sessionDurationSec)}>
          <FormLabel whiteSpace="nowrap">Session exp. (sec)</FormLabel>
          <Input type="number" {...register('sessionDurationSec')} />
          <FormErrorMessage>
            {errors.sessionDurationSec && errors.sessionDurationSec.message}
          </FormErrorMessage>
        </FormControl>

        {isSAML && (
          <FormControl isInvalid={Boolean(errors.userActionMode)}>
            <FormLabel>How user will confirm presence</FormLabel>
            <Select
              variant="outline"
              defaultValue="-1"
              {...register('userActionMode', { valueAsNumber: true })}
            >
              <option value="-1">System Default</option>
              {Object.values(UserActionMode)
                .filter(v => typeof v === 'number')
                .map(v => (
                  <option key={v} value={v}>
                    {UserActionModeLabels[v]}
                  </option>
                ))}
            </Select>
            <FormErrorMessage>
              {errors.userActionMode && errors.userActionMode.message}
            </FormErrorMessage>
          </FormControl>
        )}

        <FormControl isInvalid={Boolean(errors.subjectIdJwtClaimType)}>
          <FormLabel>Claim Type</FormLabel>
          <Select
            variant="outline"
            defaultValue="email"
            {...register('subjectIdJwtClaimType')}
          >
            <option value="email">Email</option>
            <option value="nameImmutableID">Name Immutable ID</option>
          </Select>
          <FormErrorMessage>
            {errors.subjectIdJwtClaimType &&
              errors.subjectIdJwtClaimType.message}
          </FormErrorMessage>
        </FormControl>
      </Stack>
      {isOIDC && (
        <FormControl display="flex">
          <FormLabel>Require PKCE</FormLabel>
          <Switch {...register('requirePkce')} />
        </FormControl>
      )}
      {isSAML && (
        <FormControl isInvalid={Boolean(errors.signingCertificate)}>
          <FormLabel>Signing Certificate</FormLabel>
          <FileInput
            control={control}
            name="signingCertificate"
            accept=".cer"
            description="The public key of the Service Provider. Necessary if incoming SAML requests should be signed"
          />
          <FormErrorMessage>
            {(errors.signingCertificate as any)?.message}
          </FormErrorMessage>
        </FormControl>
      )}
      <FormControl isInvalid={Boolean(errors.attributeMappings)}>
        <FormLabel>Attribute Map</FormLabel>
        <TableContainer shadow="md">
          <Table>
            <Thead bg="gray.50">
              <Tr>
                <Th>Attribute</Th>
                <Th>SP Attribute Name</Th>
                <Th>SP Attribute Name Format</Th>
              </Tr>
            </Thead>
            <Tbody>
              {attributeMappingFields.map((field, index) => (
                <Tr key={field.id}>
                  <Td>
                    <Input
                      display="none"
                      {...register(`attributeMappings.${index}.id`)}
                    />
                    <Input
                      display="none"
                      {...register(`attributeMappings.${index}.source`)}
                    />
                    <Tag>{DefaultAttributeMapDisplay[field.source]}</Tag>
                  </Td>
                  <Td>
                    <Input
                      size="sm"
                      {...register(`attributeMappings.${index}.targetName`)}
                    />
                  </Td>
                  <Td>
                    <Input
                      size="sm"
                      {...register(
                        `attributeMappings.${index}.targetNameFormat`,
                      )}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <FormErrorMessage>
          {errors.attributeMappings?.map(e => (
            <Flex dir="column" gap={2}>
              <Box> {e.source?.message}</Box>
              <Box>{e.targetName?.message}</Box>
              <Box>{e.targetNameFormat?.message}</Box>
            </Flex>
          ))}
        </FormErrorMessage>
      </FormControl>
    </VStack>
  )
}

export default AddServiceProviderForm
