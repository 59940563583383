import { Flex } from '@chakra-ui/react'

import { useAppState } from '../../libs/hooks'
import OnlineUserSection from './components/OnlineUserSection'
import ServiceProviderSection from './components/ServiceProviderSection'

function Home() {
  const { user } = useAppState()
  return (
    <Flex flexDirection="column" gap="10">
      {user.isAdmin && <OnlineUserSection />}
      <ServiceProviderSection />
    </Flex>
  )
}

export default Home
