import React, { ReactEventHandler } from 'react'
import { Link, Redirect, Route, Switch, useHistory } from 'react-router-dom'
import {
  CSSObject,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { TabDescription } from '../libs/hooks'

interface Props {
  tabs: TabDescription[]
  currentTabIndex: number
}

export function Page({ tabs, currentTabIndex }: Props) {
  const history = useHistory()
  const onTabChange: ReactEventHandler<HTMLSelectElement> = e => {
    history.push(e.currentTarget.value)
  }

  return (
    <Tabs isLazy index={currentTabIndex} sx={styles}>
      {tabs.length > 1 && (
        <>
          <TabList>
            {tabs.map(({ route, path, label }) => (
              <Tab key={route} as={Link} to={path || route}>
                {label}
              </Tab>
            ))}
          </TabList>
          <div className="mobile-tabs">
            <Select onChange={onTabChange}>
              {tabs.map(({ route, path, label }) => (
                <option key={route} value={path || route}>
                  {label}
                </option>
              ))}
            </Select>
          </div>
        </>
      )}
      <TabPanels py={4}>
        <Switch>
          {tabs.map(({ route, Content }) => (
            <TabPanel
              key={route}
              as={Route}
              exact
              path={route}
              component={Content}
            />
          ))}
          <Redirect to={tabs[0].route} />
        </Switch>
      </TabPanels>
    </Tabs>
  )
}

const styles: CSSObject = {
  '.chakra-tabs__tablist': {
    display: {
      base: 'none',
      sm: 'flex',
    },
  },
  '.chakra-tabs__tab': {
    fontSize: 'sm',
    fontWeight: 'medium',
  },
  '.mobile-tabs': {
    backgroundColor: 'white',
    display: {
      base: 'block',
      sm: 'none',
    },
  },
}
