import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react'
import React, { BaseSyntheticEvent, RefObject } from 'react'
import { useFormContext } from 'react-hook-form'
import { RegisterFidoKeyInput } from '../../../libs/hooks'

interface Props {
  isOpen: boolean
  leastDestructiveRef: RefObject<any>
  onOpen: () => void
  onClose: () => void
  onSubmit: (e?: BaseSyntheticEvent) => Promise<void>
}

function RegisterFidoKey({
  isOpen,
  leastDestructiveRef,
  onOpen,
  onClose,
  onSubmit,
}: Props) {
  const {
    register,
    formState: { errors, isSubmitting },
  } = useFormContext<RegisterFidoKeyInput>()
  return (
    <AlertDialog
      leastDestructiveRef={leastDestructiveRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <form onSubmit={onSubmit}>
          <AlertDialogContent>
            <AlertDialogHeader>
              Register a new FIDO Security Key
            </AlertDialogHeader>
            <AlertDialogBody>
              <FormControl isRequired isInvalid={Boolean(errors.name)}>
                <FormLabel>Key Name</FormLabel>
                <Input
                  {...register('name', {
                    validate: v => {
                      const len = v.trim().length
                      return (
                        (len > 0 && len < 255) ||
                        'Key name must have between 1 to 255 characters'
                      )
                    },
                  })}
                />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={leastDestructiveRef}
                size="sm"
                variant="ghost"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                size="sm"
                colorScheme="blue"
                ml={3}
                isLoading={isSubmitting}
              >
                Register
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </form>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default RegisterFidoKey
