import { useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import * as yup from 'yup'

import {
  GroupDto,
  GroupsClient,
  IGroupDto,
  IPagedResultDtoOfServiceProviderDto,
  IPagedResultDtoOfUserDto,
  IServiceProvidersToGroupInput,
  IUsersToGroupInput,
  PagedResultDtoOfGroupDto,
  PagedResultDtoOfServiceProviderDto,
  PagedResultDtoOfUserDto,
  ServiceProvidersClient,
  ServiceProvidersToGroupInput,
  UsersClient,
  UsersToGroupInput,
} from '../../../ses.idp.web.api'
import { ProblemDetails, throwProblemDetails } from '../../types'

export const ALL_USER_GROUP_ID = '1'
export const ALL_DEVICE_GROUP_ID = '2'

export const QK_GET_GROUPS = 'getGroups'
export const QK_GET_GROUP = 'getGroup'
export const QK_GET_USERS_OF_GROUP = 'getUsersOfGroup'
export const QK_GET_ASSIGNABLE_USERS_OF_GROUP = 'getAssignableUsersOfGroup'
export const QK_GET_SPS_OF_GROUP = 'getSpsOfGroup'
export const MK_CREATE_GROUP = 'createGroup'
export const MK_DELETE_GROUP = 'deleteGroup'
export const MK_UPDATE_GROUP_SETTINGS = 'updateGroupSettings'
export const MK_ASSIGN_USERS_TO_GROUP = 'assignUsersToGroup'
export const MK_REMOVE_USERS_FROM_GROUP = 'removeUsersFromGroup'
export const MK_ASSIGN_SPS_TO_GROUP = 'assignSpsToGroup'
export const MK_REMOVE_SPS_FROM_GROUP = 'removeSpsFromGroup'

export const groupFormSchema: yup.SchemaOf<IGroupDto> = yup.object({
  id: yup.number().notRequired(),
  allowOob: yup.boolean().notRequired(),
  dateCreatedUtc: yup.date().notRequired(),
  description: yup
    .string()
    .trim()
    .required('Description is required')
    .min(1, 'Description must be at least 1 character')
    .max(100, 'Description must 100 characters or less'),
  displayName: yup
    .string()
    .trim()
    .required('Name is required')
    .min(1, 'Name must be at least 1 character')
    .max(100, 'Name must be 100 characters or less')
    .matches(
      /^(?!.*[\\/"[\]:|<>+=;,?*]).*[^.]$/u,
      'Group name may not include any of following characters: \\/ []: <>=;,? and cannot end with a period',
    ),
})

export function useGroups({
  excludeIds,
  enabled = true,
  initialPage = 1,
}: {
  enabled?: boolean
  initialPage?: number
  excludeIds?: number | number[]
}) {
  const [page, setPage] = useState(initialPage)
  const [search, setSearch] = useState('')
  const queryKey = [QK_GET_GROUPS, page, search]

  const { data, isLoading, refetch } = useQuery<PagedResultDtoOfGroupDto>(
    queryKey,
    () => {
      return new GroupsClient()
        .get(null, null, null, null, search, page, 10)
        .catch(throwProblemDetails)
    },
    {
      enabled: enabled,
    },
  )

  const createGroupMutation = useMutation<GroupDto, ProblemDetails, IGroupDto>(
    MK_CREATE_GROUP,
    input => {
      return new GroupsClient()
        .post(new GroupDto(input))
        .catch(throwProblemDetails)
    },
    {
      onSuccess: newGroup => {
        return refetch({queryKey})
      },
    },
  )

  const deleteGroupsMutation = useMutation<void, ProblemDetails, number>(
    MK_DELETE_GROUP,
    id => {
      return new GroupsClient().delete(id).catch(throwProblemDetails)
    },
    {
      onSuccess: (_, id) => {
        return refetch({queryKey})
      },
    },
  )

  const { items, total } = useMemo(() => {
    if (!data || !data.items || !data.totalCount) return { items: [], total: 0 }

    if (!excludeIds) return { items: data.items, total: data.totalCount }

    const excludedIds = Array.isArray(excludeIds) ? excludeIds : [excludeIds]
    const filtered = data.items.filter(g => !excludedIds.includes(g.id)) ?? []
    const diffTotal = data.items.length - filtered.length

    return { items: filtered, total: data.totalCount - diffTotal }
  }, [data, excludeIds])

  return {
    page,
    setPage,
    setSearch,
    isLoading,
    items,
    total,
    createAsync: createGroupMutation.mutateAsync,
    deleteAsync: deleteGroupsMutation.mutateAsync,
  } as const
}

export function useGroup(groupId: string) {
  return useQuery<IGroupDto>(
    [QK_GET_GROUP, groupId],
    () => {
      return new GroupsClient().get2(Number(groupId)).catch(throwProblemDetails)
    },
    {
      enabled: Boolean(groupId),
    },
  )
}

export function useUsersOfGroup(groupId: string) {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState<string>('')
  const query = useQuery<PagedResultDtoOfUserDto>(
    [QK_GET_USERS_OF_GROUP, groupId, page, search],
    () => {
      return new UsersClient()
        .get(null, Number(groupId), search, page, 20)
        .catch(throwProblemDetails)
    },
    {
      enabled: Boolean(groupId),
    },
  )
  return {
    query,
    page,
    setPage,
    search,
    setSearch,
  } as const
}

export function useAssignableUsersOfGroup(groupId: string | null | undefined) {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const query = useQuery<PagedResultDtoOfUserDto>(
    [QK_GET_ASSIGNABLE_USERS_OF_GROUP, groupId, page, search],
    () => {
      return new UsersClient()
        .get(null, -Number(groupId), search, page, 20)
        .catch(throwProblemDetails)
    },
    {
      enabled: Boolean(groupId),
    },
  )
  return { query, page, setPage, search, setSearch } as const
}

export function useServiceProvidersOfGroup({
  groupId,
  initialPage = 1,
  fetchOidcServiceProviders = true,
  fetchSamlServiceProviders = true,
}: {
  groupId: number
  initialPage?: number
  fetchOidcServiceProviders?: boolean
  fetchSamlServiceProviders?: boolean
}) {
  const [oidcSearch, setOidcSearch] = useState('')
  const [oidcPage, setOidcPage] = useState(initialPage)

  const [samlSearch, setSamlSearch] = useState('')
  const [samlPage, setSamlPage] = useState(initialPage)

  const queryClient = useQueryClient()

  const useData = (
    page: number,
    search: string,
    variant: string,
    enabled: boolean,
  ) =>
    useQuery<PagedResultDtoOfServiceProviderDto>(
      [QK_GET_SPS_OF_GROUP, groupId, page, variant, search],
      () => {
        return new ServiceProvidersClient()
          .get(variant, null, groupId, null, search, page, 20)
          .catch(throwProblemDetails)
      },
      { enabled },
    )

  const useAssign = (page: number, search: string, variant: string) =>
    useMutation<void, ProblemDetails, IServiceProvidersToGroupInput>(
      [MK_ASSIGN_SPS_TO_GROUP, groupId],
      input => {
        return new GroupsClient()
          .addServiceProvidersToGroup(new ServiceProvidersToGroupInput(input))
          .catch(throwProblemDetails)
      },
      {
        onSuccess: () => {
          queryClient
            .refetchQueries([
              QK_GET_SPS_OF_GROUP,
              groupId,
              page,
              variant,
              search,
            ])
            .then()
        },
      },
    )

  const useRemove = (page: number, search: string, variant: string) =>
    useMutation<void, ProblemDetails, IServiceProvidersToGroupInput>(
      [MK_REMOVE_SPS_FROM_GROUP, groupId],
      input => {
        return new GroupsClient()
          .removeServiceProvidersFromGroup(
            new ServiceProvidersToGroupInput(input),
          )
          .catch(throwProblemDetails)
      },
      {
        onSuccess: () =>
          queryClient.refetchQueries<IPagedResultDtoOfServiceProviderDto>([
            QK_GET_SPS_OF_GROUP,
            groupId,
            page,
            variant,
            search,
          ]),
            },
          )

  const samlData = useData(
    samlPage,
    samlSearch,
    'saml',
    fetchSamlServiceProviders,
    )
  const assignSamlData = useAssign(samlPage, samlSearch, 'saml')
  const removeSamlData = useRemove(samlPage, samlSearch, 'saml')

  const oidcData = useData(
    oidcPage,
    oidcSearch,
    'oidc',
    fetchOidcServiceProviders,
  )
  const assignOidcData = useAssign(oidcPage, oidcSearch, 'oidc')
  const removeOidcData = useRemove(oidcPage, oidcSearch, 'oidc')

  return {
    saml: {
      page: samlPage,
      setPage: setSamlPage,
      items: samlData.data?.items ?? [],
      total: samlData?.data?.totalCount ?? 0,
      isLoading: samlData.isLoading,
      assign: assignSamlData.mutateAsync,
      remove: removeSamlData.mutateAsync,
      search: samlSearch,
      setSearch: setSamlSearch,
    },
    oidc: {
      page: oidcPage,
      setPage: setOidcPage,
      items: oidcData?.data?.items ?? [],
      total: oidcData?.data?.totalCount ?? 0,
      isLoading: oidcData.isLoading,
      assign: assignOidcData.mutateAsync,
      remove: removeOidcData.mutateAsync,
      search: oidcSearch,
      setSearch: setOidcSearch,
    },
  } as const
}

export function useUpdateGroupSettings(groupId: string) {
  const queryClient = useQueryClient()
  return useMutation<IGroupDto, ProblemDetails, IGroupDto>(
    [MK_UPDATE_GROUP_SETTINGS, groupId],
    input => {
      return new GroupsClient()
        .put(new GroupDto({ ...input, id: Number(groupId) }))
        .catch(throwProblemDetails)
    },
    {
      onSuccess: data => {
        queryClient.setQueryData<IGroupDto>([QK_GET_GROUP, groupId], () => ({
          ...data,
        }))
      },
    },
  )
}

export function useAssignUsersToGroup(id: string, currentPage: number) {
  const queryClient = useQueryClient()
  return useMutation<void, ProblemDetails, IUsersToGroupInput>(
    [MK_ASSIGN_USERS_TO_GROUP, id, currentPage],
    input => {
      return new GroupsClient()
        .addUsersToGroup(new UsersToGroupInput(input))
        .catch(throwProblemDetails)
    },
    {
      onSuccess: (_, input) => {
        // todo: this will re-fetch all pages, we should re-fetch only current page
        // cannot use `currentPage` argument because it's the page number of a different table
        queryClient.refetchQueries([QK_GET_USERS_OF_GROUP, id]).then()
      },
    },
  )
}

export function useRemoveUsersFromGroup(id: string, currentPage: number) {
  const queryClient = useQueryClient()
  return useMutation<void, ProblemDetails, IUsersToGroupInput>(
    [MK_REMOVE_USERS_FROM_GROUP, id, currentPage],
    input => {
      return new GroupsClient()
        .removeUsersFromGroup(new UsersToGroupInput(input))
        .catch(throwProblemDetails)
    },
    {
      onSuccess: () =>
        queryClient.refetchQueries<IPagedResultDtoOfUserDto>([
          QK_GET_USERS_OF_GROUP,
          id,
          currentPage,
        ]),
    },
  )
}
