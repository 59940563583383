import { useMutation, useQuery, useQueryClient } from 'react-query'

import {
  AttestationConveyancePreference,
  AuthenticationExtensionsClientOutputs,
  AuthenticatorAttachment,
  AuthenticatorAttestationRawResponse,
  CredentialCreateOptions as ServerCredentialCreationOptions,
  FidoKeysClient,
  IAuthenticationExtensionsClientOutputs,
  IIdpFidoKeyDto,
  IPubKeyCredentialDto,
  PubKeyCredentialCreationOptionsDto,
  PubKeyCredentialDto,
  PublicKeyCredentialType,
  ResponseData,
  UserVerificationRequirement,
} from '../../../ses.idp.web.api'

import {
  IProblemDetails,
  ProblemDetails,
  throwProblemDetails,
} from '../../types'

export const QK_LIST_FIDO_KEYS = 'getUserFidoKeys'
export const MK_REGISTER_FIDO_KEY = 'registerPubKeyCredential'
export const MK_DELETE_FIDO_KEY = 'deleteFidoKey'

export interface RegisterFidoKeyInput {
  name: string
}

export interface DeleteFidoKeyInput {
  id: number
  name: string
}

export function useFidoKeys(flags: string) {
  return useQuery<IIdpFidoKeyDto[], IProblemDetails>(QK_LIST_FIDO_KEYS, () => {
    return new FidoKeysClient().get(0, 15, flags).catch(throwProblemDetails)
  })
}

export function useRegisterFidoKey() {
  const queryClient = useQueryClient()
  return useMutation<IIdpFidoKeyDto, ProblemDetails, RegisterFidoKeyInput>(
    MK_REGISTER_FIDO_KEY,
    ({ name }: RegisterFidoKeyInput) => {
      const client = new FidoKeysClient()
      return client
        .getPubKeyCredentialCreationOptions(
          new PubKeyCredentialCreationOptionsDto({
            requireResidentKey: true,
            userVerification: UserVerificationRequirement.Discouraged,
            authType: AuthenticatorAttachment.CrossPlatform,
            attType: AttestationConveyancePreference.None,
          }),
        )
        .then(serverOpts => {
          const options = toCredentialCreationOptions(serverOpts)
          return navigator.credentials.create(options).then(c => ({
            credentials: c as PublicKeyCredential,
            serverOptions: serverOpts,
          }))
        })
        .then(({ credentials, serverOptions }) => {
          const attestationResponse =
            credentials.response as AuthenticatorAttestationResponse
          const attestationObject = new Uint8Array(
            attestationResponse.attestationObject,
          )
          const clientDataJSON = new Uint8Array(
            attestationResponse.clientDataJSON,
          )
          const dto: IPubKeyCredentialDto = {
            keyName: name,
            credentialCreateOptions: serverOptions,
            attestationResponse: new AuthenticatorAttestationRawResponse({
              id: credentials.id,
              rawId: coerceToBase64Url(credentials.rawId),
              type: credentials.type as PublicKeyCredentialType,
              extensions: new AuthenticationExtensionsClientOutputs(
                credentials.getClientExtensionResults() as IAuthenticationExtensionsClientOutputs,
              ),
              response: new ResponseData({
                attestationObject: coerceToBase64Url(attestationObject),
                clientDataJson: coerceToBase64Url(clientDataJSON),
              }),
            }),
          }
          return client.register(new PubKeyCredentialDto(dto))
        })
        .catch(throwProblemDetails)
    },
    {
      onSuccess: newKey => {
        queryClient.setQueryData<IIdpFidoKeyDto[]>(QK_LIST_FIDO_KEYS, prev => [
          ...prev,
          newKey,
        ])
      },
    },
  )
}

export function useDeleteFidoKey() {
  const queryClient = useQueryClient()
  return useMutation<IIdpFidoKeyDto, IProblemDetails, DeleteFidoKeyInput>(
    MK_DELETE_FIDO_KEY,
    ({ id }) => new FidoKeysClient().delete(id).catch(throwProblemDetails),
    {
      onSuccess: deletedKey => {
        queryClient.setQueryData<IIdpFidoKeyDto[]>(QK_LIST_FIDO_KEYS, prev =>
          prev.filter(k => k.id !== deletedKey.id),
        )
      },
    },
  )
}

function toCredentialCreationOptions(
  credentialOptions: ServerCredentialCreationOptions,
): CredentialCreationOptions {
  return {
    publicKey: {
      challenge: coerceToArrayBuffer(credentialOptions.challenge, 'challenge'),
      user: {
        id: coerceToArrayBuffer(credentialOptions.user.id, 'user.id'),
        displayName: credentialOptions.user.displayName,
        name: credentialOptions.user.name,
      },
      authenticatorSelection: {
        ...credentialOptions.authenticatorSelection,
        authenticatorAttachment:
          credentialOptions.authenticatorSelection.authenticatorAttachment ??
          undefined,
      },
      excludeCredentials: credentialOptions.excludeCredentials.map(p => {
        return {
          ...p,
          id: coerceToArrayBuffer(p.id, 'PublicKeyCredentialDescriptor.id'),
        } as PublicKeyCredentialDescriptor
      }),
      extensions: credentialOptions.extensions as any,
      pubKeyCredParams:
        credentialOptions.pubKeyCredParams as PublicKeyCredentialParameters[],
      rp: credentialOptions.rp as PublicKeyCredentialRpEntity,
      timeout: credentialOptions.timeout,
      attestation: credentialOptions.attestation,
    },
  }
}

function coerceToArrayBuffer(thing: any, name: string) {
  if (typeof thing === 'string') {
    // base64url to base64
    thing = thing.replace(/-/g, '+').replace(/_/g, '/')

    // base64 to Uint8Array
    const str = window.atob(thing)
    const bytes = new Uint8Array(str.length)
    for (let i = 0; i < str.length; i++) {
      bytes[i] = str.charCodeAt(i)
    }
    thing = bytes
  }

  // Array to Uint8Array
  if (Array.isArray(thing)) {
    thing = new Uint8Array(thing)
  }

  // Uint8Array to ArrayBuffer
  if (thing instanceof Uint8Array) {
    thing = thing.buffer
  }

  // error if none of the above worked
  if (!(thing instanceof ArrayBuffer)) {
    throw new TypeError(`could not coerce '${name}' to ArrayBuffer`)
  }

  return thing
}

function coerceToBase64Url(thing: any) {
  // Array or ArrayBuffer to Uint8Array
  if (Array.isArray(thing)) {
    thing = Uint8Array.from(thing)
  }

  if (thing instanceof ArrayBuffer) {
    thing = new Uint8Array(thing)
  }

  // Uint8Array to base64
  if (thing instanceof Uint8Array) {
    let str = ''
    const len = thing.byteLength

    for (let i = 0; i < len; i++) {
      str += String.fromCharCode(thing[i])
    }
    thing = window.btoa(str)
  }

  if (typeof thing !== 'string') {
    throw new Error('could not coerce to string')
  }

  // base64 to base64url
  // NOTE: "=" at the end of challenge is optional, strip it off here
  thing = thing.replace(/\+/g, '-').replace(/\//g, '_').replace(/=*$/g, '')

  return thing
}
