import React, { useRef, useState } from 'react'
import {
  Portal,
  VStack,
  useDisclosure,
  ButtonGroup,
  Button,
  Box,
} from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FaPlus } from 'react-icons/fa'

import utils from '../../../libs/utils'
import {
  addServiceProviderSchema,
  getDefaultAttributeMap,
  ServiceProviderInputType,
  ServiceProviderVariant,
  useAddServiceProvider,
  useDeleteServiceProvider,
  useServerCapabilities,
  useServiceProviders,
} from '../../../libs/hooks'
import { ProblemDetails } from '../../../libs/types'
import { EmptyState, SectionHeader } from '../../../components'

import ServiceProviderTable from './components/ServiceProviderTable'
import AddServiceProviderModal from './components/AddServiceProviderModal'
import DeleteSAMPLModal from './components/DeleteSAMPLModal'
import ServerCapabilitiesForm from './components/ServerCapabilitiesForm'
import { ServiceProviderMenu } from './components/ServiceProviderMenu'

interface ServiceProviderConfigProps {
  variant: ServiceProviderVariant
}

export function ServiceProviderConfiguration({
  variant,
}: ServiceProviderConfigProps) {
  const cancelRef = useRef()
  const [currentPage, setCurrentPage] = useState(1)
  const {
    query: { data: providers, isLoading },
    setSearch,
  } = useServiceProviders({
    variant,
    page: currentPage,
  })
  const deleteSp = useDeleteServiceProvider(variant)
  const addSp = useAddServiceProvider(variant, currentPage)
  const deleteForm = useForm<{
    id: number
  }>()
  const addForm = useForm<ServiceProviderInputType>({
    resolver: yupResolver(addServiceProviderSchema),
  })
  const deleteModal = useDisclosure()
  const addModal = useDisclosure({
    onClose() {
      addForm.setValue('signingCertificate', null)
    },
  })

  const onAddModalOpen = () => {
    addForm.reset({
      formType: 'add',
      variant,
      attributeMappings: getDefaultAttributeMap(),
    })
    addModal.onOpen()
  }

  const onEditModalOpen = (id: number) => {
    const sp = providers.items.find(p => p.id === id)
    const cert = sp.signingCertificates && sp.signingCertificates[0]

    let attributeMappings = getDefaultAttributeMap()
    if (sp.attributeMappings) {
      const updates = Object.fromEntries(
        sp.attributeMappings.map(o => [o.source, o]),
      )
      attributeMappings = attributeMappings.map(o => updates[o.source] || o)
    }

    addForm.reset({
      ...sp,
      id,
      variant,
      formType: 'edit',
      userActionMode: sp.userActionMode ?? -1,
      signingCertificate: cert
        ? {
            fileName: cert.name,
          }
        : null,
      attributeMappings,
    })
    addModal.onOpen()
  }

  const onDeleteModalOpen = (id: number) => {
    deleteForm.reset({ id })
    deleteModal.onOpen()
  }

  const handleDelete = deleteForm.handleSubmit(({ id }) => {
    return deleteSp
      .mutateAsync(id, {
        onError: utils.toastError,
        onSuccess: () => {
          utils.toastSuccess('The Service Provider has been deleted')
          deleteModal.onClose()
        },
      })
      .catch(_ => {})
  })

  const handleAdd = addForm.handleSubmit(data => {
    return addSp
      .mutateAsync(data, {
        onError: error => {
          const pd = ProblemDetails.parse(error)
          if (pd.isValidationError) {
            ProblemDetails.setHookFormError(addForm.setError, pd)
          } else {
            utils.toastError(pd)
          }
        },
        onSuccess: (data, variables) => {
          utils.toastSuccess(
            `The Service Provider has been ${
              variables.formType === 'edit' ? 'updated' : 'created'
            }`,
          )
          addForm.reset({})
          addModal.onClose()
        },
      })
      .catch(_ => {})
  })

  return (
    <Box>
      <SectionHeader
        title="Service Providers"
        description={`A list of all Service Providers for ${variant}`}
      >
        <ButtonGroup isAttached size="sm" variant="solid" colorScheme="blue">
          <Button
            leftIcon={<FaPlus />}
            borderRightRadius={variant === 'SAML' ? 0 : undefined}
            borderRight="1px"
            borderRightColor="whiteAlpha.800"
            onClick={onAddModalOpen}
          >
            Add Service Provider
          </Button>
          {variant === 'SAML' && (
            <ServiceProviderMenu
              openModal={addModal.onOpen}
              setFormValues={addForm.reset}
            />
          )}
        </ButtonGroup>
      </SectionHeader>
      <ServiceProviderTable
        variant={variant}
        currentPage={currentPage}
        providers={providers?.items}
        total={providers?.totalCount ?? 0}
        perPage={10}
        onEdit={onEditModalOpen}
        onRemove={onDeleteModalOpen}
        onPageChange={setCurrentPage}
        isLoading={isLoading}
        onSearch={setSearch}
        emptyState={
          <EmptyState
            onClick={onAddModalOpen}
            label="Add a new service provider"
          />
        }
      />
      <Portal>
        <FormProvider {...deleteForm}>
          <DeleteSAMPLModal
            leastDestructiveRef={cancelRef}
            onSubmit={handleDelete}
            {...deleteModal}
          />
        </FormProvider>
        <FormProvider {...addForm}>
          <AddServiceProviderModal onSubmit={handleAdd} {...addModal} />
        </FormProvider>
      </Portal>
    </Box>
  )
}

function SAMLConfiguration() {
  const { data: capabilities } = useServerCapabilities()
  return (
    <VStack alignItems="stretch" spacing={10}>
      <ServerCapabilitiesForm capabilities={capabilities} />
      <ServiceProviderConfiguration variant="SAML" />
    </VStack>
  )
}

export default SAMLConfiguration
