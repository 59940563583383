import React, { useEffect } from 'react'
import { Box, Button, Center, FormControl, Spinner } from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useParams } from 'react-router-dom'

import { IGroupDto } from '../../../ses.idp.web.api'
import {
  groupFormSchema,
  useGroup,
  useUpdateGroupSettings,
} from '../../../libs/hooks'

import GroupForm from './GroupForm'

function GroupSettings() {
  const { id } = useParams<{ id: string }>()
  const { data: group, isLoading } = useGroup(id)
  const { mutateAsync: updateAsync } = useUpdateGroupSettings(id)
  const { reset, ...form } = useForm<IGroupDto>({
    resolver: yupResolver(groupFormSchema),
  })

  useEffect(() => {
    if (!group) return
    reset(group)
  }, [group, reset])

  const handleSubmit = form.handleSubmit(input => {
    return updateAsync(input).catch(_ => {})
  })

  return (
    <FormProvider reset={reset} {...form}>
      {isLoading ? (
        <Center minH="72">
          <Spinner />
        </Center>
      ) : (
        <Box bg="white" shadow="md" rounded="lg" p={8} mt={4}>
          <form onSubmit={handleSubmit}>
            <GroupForm>
              <FormControl>
                <Button
                  colorScheme="blue"
                  type="submit"
                  isDisabled={!form.formState.isDirty}
                  isLoading={form.formState.isSubmitting}
                >
                  Save
                </Button>
              </FormControl>
            </GroupForm>
          </form>
        </Box>
      )}
    </FormProvider>
  )
}

export default GroupSettings
