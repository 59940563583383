import React, { RefObject } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'

function RemoveServiceProviderModal({
  onClose,
  onSubmit,
  isSubmitting,
  ...props
}: {
  leastDestructiveRef: RefObject<any>
  isSubmitting: boolean
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  onSubmit: () => Promise<void>
}) {
  return (
    <AlertDialog onClose={onClose} {...props}>
      <AlertDialogOverlay>
        <form onSubmit={onSubmit}>
          <AlertDialogContent>
            <AlertDialogHeader>Remove Service Provider</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to remove the Service Provider from this
              Group?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button size="sm" variant="ghost" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                size="sm"
                colorScheme="red"
                ml={3}
                isLoading={isSubmitting}
              >
                Remove
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </form>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default RemoveServiceProviderModal
