import { useRef } from 'react'
import { useParams } from 'react-router-dom'
import { FaPlus } from 'react-icons/fa'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, Button, Portal, useDisclosure } from '@chakra-ui/react'

import { useServiceProvidersOfGroup } from '../../../libs/hooks'
import { EmptyState, SectionHeader } from '../../../components'
import { IServiceProvidersToGroupInput } from '../../../ses.idp.web.api'
import ServiceProviderTable from '../../Configurations/SAMPL/components/ServiceProviderTable'

import AddServiceProvidersModal from './AddServiceProvidersModal'
import RemoveServiceProviderModal from './RemoveServiceProviderModal'
import utils from '../../../libs/utils'

export type GroupServiceProvidersProps = {
  variant: 'OIDC' | 'SAML'
}

function GroupServiceProviders({ variant }: GroupServiceProvidersProps) {
  const cancelRef = useRef()
  const addModal = useDisclosure()
  const removeModal = useDisclosure()
  const { id } = useParams<{ id: string }>()
  const { oidc, saml } = useServiceProvidersOfGroup({
    groupId: Number(id),
    fetchSamlServiceProviders: variant === 'SAML',
    fetchOidcServiceProviders: variant === 'OIDC',
  })
  const defaultValues: IServiceProvidersToGroupInput = {
    groupId: Number(id),
    serviceProviderIds: [],
  }
  const addForm = useForm<IServiceProvidersToGroupInput>({ defaultValues })
  const removeForm = useForm<IServiceProvidersToGroupInput>({ defaultValues })

  const variantData = variant === 'SAML' ? saml : oidc

  const handleAddSubmit = addForm.handleSubmit(input => {
    return variantData.assign(input, {
      onError: utils.toastError,
      onSuccess: () => {
        addModal.onClose()
        utils.toastSuccess()
      },
    }).catch(_ => {})
  })

  const handleRemoveSubmit = removeForm.handleSubmit(input => {
    return variantData.remove(input, {
      onError: utils.toastError,
      onSuccess: () => {
        removeModal.onClose()
        utils.toastSuccess()
      },
    }).catch(_ => {})
  })

  const handleRemoveClick = (id: number) => {
    removeForm.setValue('serviceProviderIds', [id])
    removeModal.onOpen()
  }

  return (
    <Box>
      <SectionHeader
        description={`${variant} Service Providers that can be access by Users of this Group.`}
      >
        <Button
          size="sm"
          colorScheme="blue"
          leftIcon={<FaPlus />}
          onClick={addModal.onOpen}
        >
          Add Service Providers
        </Button>
      </SectionHeader>
      <ServiceProviderTable
        variant={variant}
        currentPage={variantData.page}
        onPageChange={variantData.setPage}
        isLoading={variantData.isLoading}
        total={variantData.total}
        providers={variantData.items}
        perPage={20}
        onRemove={handleRemoveClick}
        onSearch={variantData.setSearch}
        emptyState={
          <EmptyState
            onClick={addModal.onOpen}
            label="Add a Service Provider to this Group"
          />
        }
      />
      <Portal>
        <FormProvider {...addForm}>
          <AddServiceProvidersModal
            groupId={id}
            variant={variant}
            onSubmit={handleAddSubmit}
            {...addModal}
          />
        </FormProvider>
        <RemoveServiceProviderModal
          leastDestructiveRef={cancelRef}
          isSubmitting={removeForm.formState.isSubmitting}
          onSubmit={handleRemoveSubmit}
          {...removeModal}
        />
      </Portal>
    </Box>
  )
}

export default GroupServiceProviders
