import React from 'react'
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Stack,
} from '@chakra-ui/react'

import ServiceProviderTable from '../../Configurations/SAMPL/components/ServiceProviderTable'
import { useServiceProvidersOfGroup } from '../../../libs/hooks'
import { useFormContext } from 'react-hook-form'
import { IServiceProvidersToGroupInput } from '../../../ses.idp.web.api'

interface Props {
  groupId: string
  variant: 'OIDC' | 'SAML'
  isOpen: boolean
  onClose: () => void
  onSubmit: () => Promise<void>
}

function AddServiceProvidersModal({
  groupId,
  variant,
  isOpen,
  onSubmit,
  onClose,
}: Props) {
  const { oidc, saml } = useServiceProvidersOfGroup({
    groupId: -Number(groupId),
    fetchSamlServiceProviders: isOpen && variant === 'SAML',
    fetchOidcServiceProviders: isOpen && variant === 'OIDC',
  })
  const data = variant === 'SAML' ? saml : oidc

  const {
    setValue,
    formState: { isSubmitting, isDirty },
  } = useFormContext<IServiceProvidersToGroupInput>()

  function handleSelect(selection: number[]) {
    setValue('serviceProviderIds', selection, {
      shouldDirty: true,
    })
  }

  return (
    <Drawer size="xl" placement="right" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <form onSubmit={onSubmit}>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader w="full">
            <Stack
              w="full"
              direction={{ base: 'column', sm: 'row' }}
              spacing={3}
              alignItems={{ sm: 'center' }}
            >
              <span aria-label="Add Group">Add Service Providers to Group</span>
            </Stack>
          </DrawerHeader>
          <DrawerBody>
            <ServiceProviderTable
              variant={variant}
              providers={data?.items}
              isLoading={data.isLoading}
              currentPage={data.page}
              total={data.total}
              perPage={20}
              onSelect={handleSelect}
              onPageChange={data.setPage}
            />
          </DrawerBody>
          <DrawerFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              ml={4}
              type="submit"
              isLoading={isSubmitting}
              isDisabled={!isDirty}
            >
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  )
}

export default AddServiceProvidersModal
