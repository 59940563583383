import React from 'react'
import {
  Box,
  BoxProps,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react'
import { FaSearch } from 'react-icons/fa'
import { useForm } from 'react-hook-form'

interface SearchBoxProps extends BoxProps {
  onSearch: (value: string) => void
  placeholder?: string
}

export function SearchBox({
  onSearch,
  placeholder,
  ...boxProps
}: SearchBoxProps) {
  const { register, handleSubmit } = useForm({
    defaultValues: { search: '' },
  })

  const handleSearch = handleSubmit(input => {
    return onSearch(input.search)
  })

  return (
    <Box {...boxProps}>
      <form onSubmit={handleSearch}>
        <InputGroup>
          <InputLeftElement as="button" type="submit">
            <FaSearch />
          </InputLeftElement>
          <Input
            variant="outline"
            bg="white"
            placeholder={placeholder ?? 'Search...'}
            {...register('search')}
          />
        </InputGroup>
      </form>
    </Box>
  )
}
