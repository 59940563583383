import React from 'react'
import { Redirect } from 'react-router-dom'

import utils from '../../libs/utils'
import { useAppState, useLogin } from '../../libs/hooks'

import AuthForm from './AuthForm'

function Authenticate() {
  const { user, isInstalled } = useAppState()
  const { login } = useLogin()

  if (!isInstalled && utils.isLoopbackAddress(window.location.hostname)) {
    return <Redirect to="/install" />
  }

  if (user) {
    return <Redirect to="/" />
  }

  return <AuthForm onSubmit={login} />
}

export default Authenticate
