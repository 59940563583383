import React from 'react'
import { Box, chakra } from '@chakra-ui/react'

export interface SectionHeaderProps {
  title?: string
  description?: string
  children?: React.ReactNode
}

export function SectionHeader({
  title,
  description,
  children,
}: SectionHeaderProps) {
  return (
    <Box display={['block', 'flex']}>
      <Box flex={['none', '1 1 auto']} my={1}>
        {title && (
          <chakra.h1 fontSize="xl" fontWeight="semibold" textColor="gray.900">
            {title}
          </chakra.h1>
        )}
        {description && (
          <chakra.p mt={2} fontSize="sm" textColor="gray.700">
            {description}
          </chakra.p>
        )}
      </Box>
      <Box my={[4, 0]} ml={[0, 16]} flex={['none', '']}>
        {children}
      </Box>
    </Box>
  )
}
