import {
  Box,
  Divider,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import dayjs from 'dayjs'

import { EmptyState, Pagination } from '../../../components'
import { IIdpLogsDto } from '../../../libs/hooks/api/users'

interface Props {
  data?: IIdpLogsDto[]
  total: number
  perPage: number
  currentPage?: number
  onPageChange?: (page: number) => void
  isLoading: boolean
}

function IdpLogsTable({
  data = [],
  total,
  perPage,
  currentPage,
  onPageChange,
  isLoading,
}: Props) {
  return (
    <Box w="full" overflow="hidden" shadow="md" rounded="lg" mt={8}>
      <Table
        variant="simple"
        size="sm"
        minW="full"
        bg="white"
        sx={{
          'tr:hover .actions': {
            visibility: 'visible',
          },
          td: {
            fontSize: 'sm',
            maxW: [14, 16, 24, 40],
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          },
        }}
      >
        <Thead bg="gray.50">
          <Tr>
            <Th w="60%">Event</Th>
            <Th w="20%">Type</Th>
            <Th w="20%">Date Created</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map(({ id, email, serviceProvider, message, dateCreated }) => (
            <Tr key={id}>
              <Td title={message}>
                <span>{message}</span>
              </Td>
              <Td title={serviceProvider}>
                <span>{serviceProvider}</span>
              </Td>
              <Td>
                <span>
                  {dayjs(dateCreated).utc(true).local().format('L LTS')}
                </span>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {isLoading && (
        <Stack bg="white" p={3} divider={<Divider />}>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
      {!isLoading && data.length === 0 && <EmptyState label="No Logs Found" />}
      <Pagination
        showing={data?.length}
        total={total}
        perPage={perPage}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </Box>
  )
}

export default IdpLogsTable
