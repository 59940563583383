import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Icon,
} from '@chakra-ui/react'
import { IoIosCloseCircle } from 'react-icons/io'

import { FileParameter } from '../../ses.idp.web.api'

function SelectedFile({
  onDelete,
  file: { fileName, data },
  showContent,
}: {
  file: FileParameter
  onDelete: () => void
  showContent?: boolean
}) {
  return (
    <Accordion allowToggle>
      <AccordionItem borderWidth="1px" borderColor="gray.200" rounded="md">
        <AccordionButton>
          <Box flex="1" textAlign="left">
            {fileName}
          </Box>
          <Icon
            as={IoIosCloseCircle}
            w={5}
            h={5}
            onClick={onDelete}
            title="Delete"
            _hover={{
              color: 'red.500',
              cursor: 'pointer',
            }}
          />
        </AccordionButton>
        {showContent && (
          <AccordionPanel maxH={300} overflow="scroll">
            {data}
          </AccordionPanel>
        )}
      </AccordionItem>
    </Accordion>
  )
}

export default SelectedFile
