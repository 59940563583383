import { useMutation, useQuery, useQueryClient } from 'react-query'

import {
  LoginClient,
  AuthMethod,
  ICurrentUserDto,
} from '../../../ses.idp.web.api'
import { ProblemDetails, throwProblemDetails } from '../../types'

const RENEW_TOKEN_INTERVAL = 60000

export interface AppUser extends ICurrentUserDto {
  fullName: string
  permissions: string[]
}

export interface IAuthResponseDto {
  taskUId: string | null | undefined
  pageUId: string | null | undefined
  reqUId: string | null | undefined
}

export interface LoginInput {
  email?: string
  method?: AuthMethod
}

export function useMe() {
  const { data: user, isLoading: isLoadingUser } = useQuery(
    'currentUser',
    (): Promise<AppUser | null> =>
      new LoginClient()
        .currentUser()
        .then((p): AppUser | null => {
          if (!p) return null
          return {
            ...p,
            fullName: [p.firstName, p.lastName].join(' ').trim(),
            permissions: p.isAdmin ? ['admin'] : [],
          }
        })
        .catch(() => null),
    { retry: false, onError: null },
  )

  useQuery('renewToken', () => new LoginClient().renewToken().catch(() => {}), {
    enabled: Boolean(user),
    refetchIntervalInBackground: true,
    refetchInterval: RENEW_TOKEN_INTERVAL,
  })

  return { user, isLoadingUser }
}

function authenticateSaml(samlRequest: string) {
  const url = '/api/v1/Authentication/AuthenticateSaml'

  const body = new FormData()
  body.append('SAMLRequest', samlRequest)
  return fetch(url, {
    method: 'POST',
    redirect: 'follow',
    body,
  })
    .then(res => {
      if (res.redirected) {
        window.location.href = res.url
      }
    })
    .catch(err => {
      const problem = ProblemDetails.parse(err)
      console.warn(problem)
      return null
    })
}

export function useLogin() {
  const mutation = useMutation<void, ProblemDetails, LoginInput>(() => {
    const client = new LoginClient()
    return client
      .getSamlRequest()
      .then(authenticateSaml)
      .catch(throwProblemDetails)
  })

  return {
    login: mutation.mutateAsync,
    isLoggingIn: mutation.isLoading,
    loginError: mutation.error,
  }
}

export function useLogout() {
  const queryClient = useQueryClient()
  const mutation = useMutation((_?: unknown) => new LoginClient().logOut(), {
    onSuccess: () =>
      queryClient.invalidateQueries().then(() => queryClient.clear()),
  })

  return {
    isLoggingOut: mutation.isLoading,
    logout: mutation.mutate,
    logoutError: mutation.error,
  }
}
