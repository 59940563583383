import { chakra, Flex, Icon, VStack } from '@chakra-ui/react'
import { GrCertificate } from 'react-icons/gr'

function FilePicker({
  description,
  onClick,
}: {
  description?: string
  onClick: () => void
}) {
  return (
    <Flex
      justifyContent="center"
      px={6}
      py={5}
      border={2}
      borderColor="gray.300"
      borderStyle="dashed"
      rounded="md"
    >
      <VStack>
        <Icon as={GrCertificate} w={12} h={12} />
        <Flex fontSize="sm" textColor="gray.600">
          <chakra.label
            display="relative"
            cursor="pointer"
            bg="white"
            fontWeight="semibold"
            textColor="blue.500"
            onClick={onClick}
          >
            <span>Upload a file</span>
          </chakra.label>
          <chakra.p pl={1}>or drag and drop</chakra.p>
        </Flex>
        <chakra.p fontSize="xs" textColor="gray.500">
          {description}
        </chakra.p>
      </VStack>
    </Flex>
  )
}

export default FilePicker
