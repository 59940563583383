import React, { useEffect } from 'react'
import {
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Switch,
  VStack,
  Select,
} from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'

import { PasswordInput } from '../../../components'
import {
  IGlobalConfigurationDto,
  UserActionMode,
} from '../../../ses.idp.web.api'
import { GlobalConfigurationInput } from '../../../libs/hooks'
import { UserActionModeLabels } from '../../../libs/types'

interface Props {
  isLoading: boolean
  data: IGlobalConfigurationDto
  onSubmit: (data: GlobalConfigurationInput) => void
}

function RadiusForm({ data, isLoading, onSubmit }: Props) {
  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<GlobalConfigurationInput>()

  useEffect(() => {
    reset(data)
  }, [data, reset])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box w="full">
        <Box display={['block', 'flex']}>
          <Box flex={['none', '1 1 auto']} my={1}>
            <chakra.h1 fontSize="xl" fontWeight="semibold" textColor="gray.900">
              RADIUS Configuration
            </chakra.h1>
          </Box>
          {isDirty && (
            <Box my={[4, 0]} ml={[0, 16]} flex={['none', '']}>
              <Button
                type="submit"
                variant="solid"
                colorScheme="blue"
                size="sm"
                isLoading={isSubmitting}
              >
                Save
              </Button>
            </Box>
          )}
        </Box>
        <VStack spacing={5} bg="white" shadow="md" rounded="lg" p={8} mt={4}>
          <FormControl
            display="flex"
            alignItems="center"
            isInvalid={Boolean(errors.radiusEnabled)}
          >
            <FormLabel mb={0}>Enabled</FormLabel>
            <Switch
              key={`radius-enabled-${isLoading}`}
              {...register('radiusEnabled')}
            />
          </FormControl>
          <FormControl isInvalid={Boolean(errors.radiusPort)}>
            <FormLabel>Port</FormLabel>
            <Controller
              control={control}
              name="radiusPort"
              rules={{
                validate: value => {
                  if (!value) return 'Port is required'

                  const v = Number(value)
                  if (v <= 0 && v > 65535)
                    return 'Port number must be between 1 and 65535'
                  if (v === data.ldapPort)
                    return `Port ${v} is already used by LDAP`
                  return true
                },
              }}
              render={({ field: { ref, ...restField } }) => (
                <NumberInput {...restField}>
                  <NumberInputField ref={ref} name={restField.name} />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              )}
            ></Controller>
            <FormErrorMessage>
              {errors.radiusPort && errors.radiusPort.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={Boolean(errors.radiusSecret)}>
            <FormLabel>Shared Secret</FormLabel>
            <PasswordInput {...register('radiusSecret')} />
            <FormErrorMessage>
              {errors.radiusSecret && errors.radiusSecret.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl>
            <FormLabel>How user will confirm presence</FormLabel>
            <Select
              {...register('radiusUserActionMode', { valueAsNumber: true })}
            >
              <option value={UserActionMode.UserPresence}>
                {UserActionModeLabels[UserActionMode.UserPresence]}
              </option>
              <option value={UserActionMode.UserVerification}>
                {UserActionModeLabels[UserActionMode.UserVerification]}
              </option>
            </Select>
          </FormControl>
          <FormControl
            display="flex"
            alignItems="center"
            isInvalid={Boolean(errors.radiusMacFilterEnabled)}
          >
            <FormLabel mb={0}>
              Reject RADIUS connection requests that don't include MAC address or if Device MAC differs
            </FormLabel>
            <Switch
              key={`radius-mac-filter-${isLoading}`}
              {...register('radiusMacFilterEnabled')}
            />
          </FormControl>
        </VStack>
      </Box>
    </form>
  )
}

export default RadiusForm
