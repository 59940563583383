import React from 'react'
import {
  InputProps,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useBoolean,
  forwardRef,
} from '@chakra-ui/react'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'

export const PasswordInput = forwardRef<InputProps, 'input'>((props, ref) => {
  const [show, setShow] = useBoolean(false)

  return (
    <InputGroup>
      <Input
        ref={ref}
        autoComplete="off"
        pr="3.5rem"
        type={show ? 'text' : 'password'}
        {...props}
      />
      <InputRightElement width="3.5rem">
        <IconButton
          variant="ghost"
          h="1.75rem"
          aria-label="show-hide"
          icon={show ? <AiFillEyeInvisible /> : <AiFillEye />}
          onClick={setShow.toggle}
        />
      </InputRightElement>
    </InputGroup>
  )
})
