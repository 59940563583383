import React, { MouseEventHandler } from 'react'
import { NavLink } from 'react-router-dom'
import {
  Button,
  Container,
  Flex,
  HStack,
  IconButton,
  Img,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  VStack,
  Avatar,
  Portal,
  chakra,
  useDisclosure,
} from '@chakra-ui/react'
import { HiLogin, HiLogout } from 'react-icons/hi'
import { FaBars, FaTimes } from 'react-icons/fa'

import { AppUser, useAppState, useLogout } from '../libs/hooks'

function getMenus(user: AppUser) {
  const menus = [
    {
      path: '/',
      label: 'Home',
      exact: true,
    },
    {
      path: '/configuration',
      label: 'Configuration',
      exact: false,
    },
  ]

  if (user.isAdmin) {
    menus.push(
      {
        path: '/iam',
        label: 'Access Management',
        exact: false,
      },
      {
        path: '/logs',
        label: 'Logs',
        exact: false,
      },
    )
  }

  return menus
}

interface MobileNavMenuProps {
  user: AppUser
  isOpen: boolean
  logout: MouseEventHandler<HTMLButtonElement>
}

function MobileNav({ isOpen, user, logout }: MobileNavMenuProps) {
  const menus = getMenus(user)

  if (!isOpen) {
    return null
  }

  return (
    <chakra.div display={{ md: 'none' }} w="full">
      <VStack
        pt={2}
        pb={3}
        spacing={1}
        alignItems="stretch"
        sx={{
          a: {
            borderLeft: '4px',
            borderColor: 'transparent',
            textColor: 'gray.600',
            display: 'block',
            pl: 3,
            pr: 4,
            py: 2,
            fontWeight: 'medium',
          },
          'a.active': {
            bg: 'blue.50',
            borderColor: 'blue.500',
            textColor: 'blue.700',
          },
        }}
      >
        {user &&
          menus.map(p => (
            <chakra.a key={p.path} as={NavLink} exact={p.exact} to={p.path}>
              {p.label}
            </chakra.a>
          ))}
      </VStack>
      <chakra.div pt={4} pb={3} borderTop={'1px'} borderColor={'gray.200'}>
        {user && (
          <>
            <chakra.div display="flex" alignItems="center" px={4}>
              <chakra.div flexShrink={0}>
                <Avatar name={user.fullName || user.userId} size="sm" />
              </chakra.div>
              <chakra.div ml={3}>
                <chakra.div fontSize="md" fontWeight="medium" color="gray.800">
                  {[user.firstName, user.lastName].join(' ').trim()}
                </chakra.div>
                <chakra.div fontSize="sm" fontWeight="medium" color="gray.500">
                  {user.userId}
                </chakra.div>
              </chakra.div>
            </chakra.div>
            <VStack mt={3} spacing={1}>
              <Button
                width="full"
                variant="ghost"
                leftIcon={user ? <HiLogout /> : <HiLogin />}
                textColor="gray.500"
                justifyContent="flex-start"
                onClick={logout}
              >
                Sign out
              </Button>
            </VStack>
          </>
        )}
      </chakra.div>
    </chakra.div>
  )
}

function NavMenu() {
  const { user } = useAppState()
  const { isOpen, onToggle } = useDisclosure()
  const { logout } = useLogout()
  const menus = getMenus(user)

  return (
    <chakra.header bg="white" shadow="sm">
      <Container maxW="container.xl" mx="auto" px={{ base: 2, sm: 4, lg: 8 }}>
        <Flex alignItems="center" justifyContent="space-between" h={16}>
          <Flex px={{ base: 2, md: 0 }} h="full">
            <Flex
              flexShrink={0}
              alignItems="center"
              sx={{
                a: {
                  ml: 4,
                  fontSize: 'md',
                  fontWeight: 'medium',
                  textColor: 'blue.700',
                },
              }}
            >
              <Img w="auto" h={8} src="/favicon.ico" />
              <chakra.a
                display={{ base: 'none', sm: 'block' }}
                as={NavLink}
                to="/"
              >
                MagicEndpoint Identity Provider
              </chakra.a>
              <chakra.a
                display={{ base: 'block', sm: 'none' }}
                as={NavLink}
                to="/"
              >
                MagicEndpoint IDP
              </chakra.a>
            </Flex>
            <HStack
              as="nav"
              ml={{ sm: 6 }}
              display={{ base: 'none', md: 'flex' }}
              spacing={{ sm: 3, md: 4 }}
              sx={{
                a: {
                  h: 'full',
                  textColor: 'gray.500',
                  borderBottom: '2px',
                  borderColor: 'transparent',
                  display: 'inline-flex',
                  alignItems: 'center',
                  px: 1,
                  pt: 1,
                  fontSize: 'sm',
                  fontWeight: 'medium',
                },
                'a.active': {
                  borderColor: 'blue.500',
                  textColor: 'gray.900',
                },
                'a:not(.active):hover': {
                  borderColor: 'gray.300',
                  textColor: 'gray.700',
                },
              }}
            >
              {user &&
                menus.map(p => (
                  <chakra.a
                    key={p.path}
                    as={NavLink}
                    exact={p.exact}
                    to={p.path}
                  >
                    {p.label}
                  </chakra.a>
                ))}
            </HStack>
          </Flex>
          <chakra.div
            ml={{ md: 4 }}
            display={{ base: 'none', md: 'flex' }}
            alignItems={{ md: 'center' }}
          >
            <chakra.div ml={3} pos="relative">
              <div>
                {user && (
                  <Menu isLazy>
                    <MenuButton
                      as={chakra.button}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      rounded="full"
                      overflow="hidden"
                      title={user.fullName || user.userId}
                    >
                      <Avatar name={user.fullName || user.userId} size="sm" />
                    </MenuButton>
                    <Portal>
                      <MenuList>
                        <MenuItem>
                          Sign in as
                          <chakra.span
                            noOfLines={1}
                            ml={1}
                            fontWeight="medium"
                            textColor="gray.800"
                            maxW="120px"
                            title={user.userId}
                          >
                            {user.userId}
                          </chakra.span>
                        </MenuItem>
                        <MenuItem icon={<HiLogout />} onClick={logout}>
                          Sign out
                        </MenuItem>
                      </MenuList>
                    </Portal>
                  </Menu>
                )}
              </div>
            </chakra.div>
          </chakra.div>
          <chakra.div
            mr={-2}
            display={{ base: 'flex', md: 'none' }}
            alignItems="center"
          >
            <IconButton
              aria-label="Open menu"
              variant="ghost"
              color="gray.400"
              icon={isOpen ? <FaTimes /> : <FaBars />}
              onClick={onToggle}
            />
          </chakra.div>
        </Flex>
      </Container>
      <MobileNav isOpen={isOpen} user={user} logout={logout} />
    </chakra.header>
  )
}

export default NavMenu
