import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '@chakra-ui/react'

import { SectionHeader } from '../../../components'
import {
  GlobalConfigurationInput,
  useGlobalConfigurations,
} from '../../../libs/hooks'
import utils from '../../../libs/utils'

import LdapForm from './LdapForm'

function LDAP() {
  const {
    query: { data, isLoading },
    mutation: { mutateAsync },
  } = useGlobalConfigurations()

  const form = useForm<GlobalConfigurationInput>()
  const {
    reset,
    formState: { isDirty, isSubmitting },
  } = form

  const handleSubmit = form.handleSubmit(input => {
    if (Number(input.ldapPort) === data.radiusPort) {
      form.setError('ldapPort', {
        message: `Port ${input.ldapPort} is already used by RADIUS`,
      })
      return Promise.resolve()
    }
    return mutateAsync(input, {
      onError: utils.toastError,
      onSuccess: () =>
        utils.toastSuccess('LDAP Configuration has been updated'),
    }).catch(_ => {})
  })

  useEffect(() => {
    reset(data)
  }, [data, reset])

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <SectionHeader title="LDAP Configuration">
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            hidden={!isDirty}
            isLoading={isSubmitting}
          >
            Save
          </Button>
        </SectionHeader>
        <LdapForm isLoading={isLoading} {...form} />
      </form>
    </div>
  )
}

export default LDAP
