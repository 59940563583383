import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Switch,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { IInstallationDto } from '../../ses.idp.web.api'
import {
  InstallProgress,
  useCurrentInstallation,
  useInstall,
} from '../../libs/hooks'
import utils from '../../libs/utils'
import { FileInput, PasswordInput } from '../../components'
import { ProblemDetails } from '../../libs/types'

import {
  InstallButton,
  InstallSection,
  InstallSectionContent,
  InstallSectionLabel,
} from './elements'

export const installationSchema: yup.SchemaOf<IInstallationDto> = yup.object({
  useRawConnectionString: yup.boolean().required(),
  sqlConnectionString: yup
    .string()
    .test('cs', 'Please enter a connection string', function (cs: string) {
      const { useRawConnectionString } = this.parent as IInstallationDto
      return !useRawConnectionString || !utils.isNullOrWhitespace(cs)
    }),
  sqlDataSource: yup
    .string()
    .test('ds', 'Please enter a data source', function (ds: string) {
      const { useRawConnectionString } = this.parent as IInstallationDto
      return useRawConnectionString || !utils.isNullOrWhitespace(ds)
    }),
  sqlInitialCatalog: yup
    .string()
    .test('ds', 'Please enter database', function (db: string) {
      const { useRawConnectionString } = this.parent
      return useRawConnectionString || !utils.isNullOrWhitespace(db)
    }),
  sqlUserId: yup
    .string()
    .test('userId', 'Please enter SQL username', function (userId: string) {
      const { sqlIntegratedSecurity } = this.parent as IInstallationDto
      return sqlIntegratedSecurity || !utils.isNullOrWhitespace(userId)
    }),
  sqlPassword: yup.string().nullable(),
  sqlIntegratedSecurity: yup.boolean().required(),
  loggingEnabled: yup.boolean().required(),
  severUrl: yup
    .string()
    .test(
      'serverUrl',
      'URL must be of the form "http(s)://[Hostname or IP]:[port]"',
      utils.isValidHttpUrl,
    )
    .required('Server URL is required'),
  keyFile: yup.mixed().nullable(),
  keyFilePin: yup.string().nullable(),
})

function Installation() {
  const history = useHistory()
  const [isDisabled, setDisabled] = useState(true)
  const { installation, isInstallationLoading } = useCurrentInstallation()
  const {
    progress,
    mutation: { mutateAsync: installAsync },
  } = useInstall()
  const {
    handleSubmit,
    register,
    reset,
    watch,
    setError,
    control,
    formState: { errors },
  } = useForm<IInstallationDto>({
    resolver: yupResolver(installationSchema),
  })
  const watchIntegratedSec = watch('sqlIntegratedSecurity')
  const watchUseRawCs = watch('useRawConnectionString')

  useEffect(() => {
    reset({ ...installation })
    if (!utils.isNullOrWhitespace(installation?.sqlConnectionString)) {
      setDisabled(true)
    }
  }, [installation, reset])

  const onSubmit = handleSubmit(data => {
    return installAsync(
      { ...data },
      {
        onSuccess: () => {
          utils.toastSuccess(
            'Installation succeeded! You will be redirected to home page',
          )
          return new Promise(resolve => setTimeout(resolve, 2000)).then(
            () => (window.location.href = data.severUrl),
          )
        },
        onError: error => {
          if (error.isValidationError) {
            ProblemDetails.setHookFormError(setError, error)
          } else {
            utils.toastError(error.title)
          }
        },
      },
    ).catch(_ => {})
  })

  if (!utils.isLoopbackAddress(window.location.hostname)) {
    return <div></div>
  }

  return (
    <Box maxW="7xl" mx="auto" py={{ base: 6, sm: 12 }} px={{ sm: 6, lg: 8 }}>
      {isDisabled && (
        <Alert status="info">
          <AlertIcon />
          IdP is already configured. Do you want to reconfigure?
          <Button
            ml={3}
            size="xs"
            colorScheme="yellow"
            onClick={() => {
              setDisabled(false)
            }}
          >
            Yes
          </Button>
          <Button
            ml={3}
            size="xs"
            colorScheme="yellow"
            onClick={() => history.push('/')}
          >
            No
          </Button>
        </Alert>
      )}
      <chakra.form
        onSubmit={onSubmit}
        pt={{ base: 6, sm: 12 }}
        sx={{
          'fieldset:disabled': {
            opacity: '0.7',
          },
        }}
      >
        <fieldset disabled={isDisabled}>
          <Box>
            <InstallSection>
              <InstallSectionLabel>
                <Heading size="md" textColor="gray.900">
                  Database Protection
                </Heading>
                <Text mt={1} textColor="gray.600">
                  The master key file to protect sensitive information in
                  database
                </Text>
              </InstallSectionLabel>
              <InstallSectionContent>
                <FormControl isInvalid={Boolean(errors.keyFile)}>
                  <FormLabel>Key file</FormLabel>
                  <FileInput
                    control={control}
                    name="keyFile"
                    accept=".dbk"
                    description="The master key file used to protect SecureDoc Enterprise Server database"
                  />
                  <FormErrorMessage>{errors.keyFile?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors.keyFilePin)}>
                  <FormLabel>Key file password</FormLabel>
                  <PasswordInput {...register('keyFilePin')} />
                  <FormErrorMessage>
                    {errors.keyFilePin?.message}
                  </FormErrorMessage>
                </FormControl>
              </InstallSectionContent>
            </InstallSection>
          </Box>
          <Box mt={{ base: 6, sm: 12 }}>
            <InstallSection>
              <InstallSectionLabel>
                <Heading size="md" textColor="gray.900">
                  SQL Server
                </Heading>
                <Text mt={1} textColor="gray.600">
                  The database connection information
                </Text>
              </InstallSectionLabel>
              <InstallSectionContent>
                {/* Connection String */}
                <FormControl display="flex" alignItems="center">
                  <FormLabel mb={0}>Raw Connection String</FormLabel>
                  <Switch {...register('useRawConnectionString')} />
                </FormControl>
                {!watchUseRawCs && (
                  <>
                    <FormControl isInvalid={Boolean(errors.sqlDataSource)}>
                      <FormLabel>Server Name</FormLabel>
                      <Input {...register('sqlDataSource')} />
                      <FormErrorMessage>
                        {errors.sqlDataSource?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={Boolean(errors.sqlInitialCatalog)}>
                      <FormLabel>Database Name</FormLabel>
                      <Input {...register('sqlInitialCatalog')} />
                      <FormErrorMessage>
                        {errors.sqlInitialCatalog?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl display="flex" alignItems="center">
                      <FormLabel mb={0}>Use Integrated Security</FormLabel>
                      <Switch
                        // force refresh because of Switch bug
                        key={`integrated-sec-${isInstallationLoading}`}
                        {...register('sqlIntegratedSecurity')}
                      />
                    </FormControl>
                    {!watchIntegratedSec && (
                      <>
                        <FormControl isInvalid={Boolean(errors.sqlUserId)}>
                          <FormLabel>Username</FormLabel>
                          <Input {...register('sqlUserId')} />
                          <FormErrorMessage>
                            {errors.sqlUserId?.message}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={Boolean(errors.sqlPassword)}>
                          <FormLabel>Password</FormLabel>
                          <PasswordInput {...register('sqlPassword')} />
                          <FormErrorMessage>
                            {errors.sqlPassword?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </>
                    )}
                  </>
                )}
                {watchUseRawCs && (
                  <FormControl isInvalid={Boolean(errors.sqlConnectionString)}>
                    <FormLabel>Connection String</FormLabel>
                    <Textarea {...register('sqlConnectionString')} />
                    <FormErrorMessage>
                      {errors.sqlConnectionString?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </InstallSectionContent>
            </InstallSection>
          </Box>
          <Box mt={{ base: 6, sm: 12 }}>
            <InstallSection>
              <InstallSectionLabel>
                <Heading size="md" textColor="gray.900">
                  Others
                </Heading>
                <Text mt={1} textColor="gray.600">
                  Other miscellaneous configurations
                </Text>
              </InstallSectionLabel>
              <InstallSectionContent>
                {/* Logging */}
                <FormControl display="flex" alignItems="center">
                  <FormLabel mb={0}>Enable Logging</FormLabel>
                  <Switch
                    key={`logging-enabled-${isInstallationLoading}`}
                    {...register('loggingEnabled')}
                  />
                  <FormErrorMessage>
                    {errors.loggingEnabled?.message}
                  </FormErrorMessage>
                </FormControl>

                {/* Server URL */}
                <FormControl isInvalid={Boolean(errors.severUrl)}>
                  <FormLabel>Server URL</FormLabel>
                  <Input {...register('severUrl')} />
                  <FormErrorMessage>
                    {errors.severUrl?.message}
                  </FormErrorMessage>
                </FormControl>
              </InstallSectionContent>
            </InstallSection>
          </Box>
        <Box mt={{ base: 6, sm: 12 }}>
          <Flex justifyContent="flex-end">
            <InstallButton type="submit" progress={progress} />
          </Flex>
        </Box>
        </fieldset>
      </chakra.form>
    </Box>
  )
}

export default Installation
