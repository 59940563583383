import React from 'react'
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom'

import 'focus-visible/dist/focus-visible'
import * as dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'

import Layout from './components/Layout'
import ProtectedRoutes from './components/ProtectedRoutes'
import AppProvider from './components/providers/AppProvider'
import Authenticate from './Pages/Authenticate'
import Configurations from './Pages/Configurations'
import Installation from './Pages/Installation'
import Home from './Pages/Home'
import Logs from './Pages/Logs'
import Iam from './Pages/IAM'

dayjs.extend(localizedFormat)
dayjs.extend(utc)

function App() {
  return (
    <AppProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/authenticate" component={Authenticate} />
          <Route path="/install" component={Installation} />
          <ProtectedRoutes loginPath="/authenticate">
            <Layout>
              <Route path="/configuration" component={Configurations} />
              <Route path="/iam" component={Iam} />
              <Route exact path="/authenticated">
                <Redirect to="/" />
              </Route>
              <Route exact path="/" component={Home} />
              <Route path="/logs" component={Logs} />
            </Layout>
          </ProtectedRoutes>
        </Switch>
      </BrowserRouter>
    </AppProvider>
  )
}

export default App
