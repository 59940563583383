import { FC } from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from 'react-query'

import { ApiException } from '../../ses.idp.web.api'
import { theme } from '../theme'
import { AppStateProvider } from './AppStateProvider'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: handleError,
    },
    mutations: {
      onError: handleError,
    },
  },
})

function handleError(err: unknown) {
  const { status } = (err as ApiException) || {}
  if (status === 401) {
    queryClient.clear()
    window.location.href = '/'
  }
}

const AppProvider: FC = ({ children }) => {
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AppStateProvider>{children}</AppStateProvider>
      </QueryClientProvider>
    </ChakraProvider>
  )
}

export default AppProvider
